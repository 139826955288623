import { PageLayout } from '@apps/purchases/services';

const commonSections: PageLayout = [
  {
    field: 'logoWithName',
    readOnly: true,
    style: {
      gridArea: 'logoWithName',
      gridColumn: 4,
      fontSize: '11px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  {
    field: 'ecosystemAddress',
    readOnly: true,
    style: {
      gridArea: 'ecosystemAddress',
      gridColumn: 4,
      fontSize: '12px',
      position: 'relative',
      marginTop: '20px',
      textAlign: 'right',
    },
  },
  {
    field: 'documentType',
    readOnly: true,
    style: {
      gridArea: 'documentType',
      fontSize: '12px',
      marginTop: '20px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'documentNumber',
    readOnly: true,
    style: {
      gridArea: 'documentNumber',
      fontSize: '12px',
      marginTop: '20px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'referenceNumber',
    style: {
      gridArea: 'referenceNumber',
      gridColumn: '4',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'creationDate',
    style: {
      gridArea: 'creationDate',
      gridColumn: '4',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'positions',
    style: {
      gridArea: 'positions',
      gridColumnEnd: 'span 4',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      marginTop: '20px',
    },
  },
  {
    field: 'deliveryTerms',
    style: {
      gridArea: 'deliveryTerms',
      fontSize: '12px',
      minWidth: '240px',
    },
  },
  {
    field: 'paymentTerms',
    style: {
      gridArea: 'paymentTerms',
      fontSize: '12px',
      minWidth: '240px',
    },
  },
  {
    field: 'footer',
    style: {
      gridArea: 'footer',
      gridColumnEnd: 'span 4',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
  },
];

export default commonSections;
