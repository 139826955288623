import { lazy } from 'react';
import {
  ShopOutlined,
  FieldTimeOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

const Purchases = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './Purchases'),
);

const BANFList = lazy(
  () =>
    import(/* webpackChunkName: "purchasesApp" */ './individualRequestsList'),
);

const PurchasesList = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './purchasesList'),
);

const OperationSteps = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './operationSteps'),
);

export default {
  appSubmenuName: 'app.purchases',
  icon: ShopOutlined,
  component: Purchases,
  sections: [
    {
      link: 'banf_list',
      title: 'app.purchases.positions',
      component: BANFList,
      icon: FieldTimeOutlined,
    },
    {
      link: 'purchases_list',
      title: 'operations',
      component: PurchasesList,
      icon: HistoryOutlined,
    },
    {
      link: 'operation_steps',
      title: 'operation.steps',
      component: OperationSteps,
      icon: HistoryOutlined,
    },
  ],
};
