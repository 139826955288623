import moment from 'moment';
import { patch } from '@services/api';
import {
  PRODUCT_CONTACT_CONSTRAINTS,
  ProductContactConstraint,
} from '@apps/products/services/namespace';

const updateProductContactConstraint = (
  id: string,
  constraint: ProductContactConstraint,
) =>
  patch(`${PRODUCT_CONTACT_CONSTRAINTS}/${id}`, {
    ...constraint,
    timestamp: moment().valueOf(),
  });

export default updateProductContactConstraint;
