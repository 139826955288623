import React, { FC } from 'react';
import { Form } from 'antd';
import { BucketStep } from '@apps/purchases/services/buckets';
import { IntlShape, useIntl } from 'react-intl';

const { Item } = Form;

const stepTypeMapper = (bucketStep: BucketStep, intl: IntlShape) => {
  return intl.formatMessage({
    id: `${bucketStep}`,
    defaultMessage: `${bucketStep}`,
  });
};

const BucketStepType: FC = () => {
  const intl = useIntl();
  return (
    <Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const stepType = getFieldValue('stepType');
        return (
          <Item name="stepType">
            <div>{stepTypeMapper(stepType as BucketStep, intl)}</div>
          </Item>
        );
      }}
    </Item>
  );
};

export default BucketStepType;
