import React, { FC, useEffect, useState } from 'react';
import { AppViewProps } from '@components/AppView/index';
import { EcosystemState } from '@components/EcosystemIndicator/store';
import { FileApp } from '@apps/AppTypes';

import { IGetRowsParams } from 'ag-grid-community';
import { CardViewEntry, CardViewWrapper } from '@components/common.sc';
import { useHistory } from 'react-router-dom';
import { ActionRendererProps } from '@components/AppView/ActionRenderer';
import { useIntl } from 'react-intl';

type CardViewProps = {
  options?: AppViewProps['options'];
  getExtraOptions?: AppViewProps['getExtraOptions'];
  fetchExtraOptions?: AppViewProps['fetchExtraOptions'];
  activeEcosystems?: EcosystemState;
  path: string;
  appConfig?: FileApp;
  getItems: (params: IGetRowsParams & { query: string }) => void;
  context: any;
  cardComponent?: React.ComponentType<any & { item: any }>;
  onCellClickPath: string;
  handleDelete?: (id: string) => void;
};

const CardView: FC<CardViewProps> = ({
  appConfig,
  path,
  getItems,
  getExtraOptions,
  fetchExtraOptions,
  options,
  context,
  cardComponent,
  onCellClickPath,
  handleDelete,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [items, setItems] = useState<any[]>([]);
  const Card = cardComponent
    ? (cardComponent as React.ComponentType<any & { item: any }>)
    : null;
  useEffect(() => {
    getItems({
      startRow: 0,
      endRow: 1000,
      successCallback: (fetchedItems: any[], _l: number) => {
        setItems(fetchedItems);
      },
      failCallback: () => {
        console.warn('error');
      },
      filterModel: {},
      context,
      sortModel: [],
      query: '',
    });
  }, [getItems, context]);
  return Card && items.length ? (
    <CardViewWrapper>
      {items.map((item, index) => {
        const onCardClick = () => {
          if (item.id && onCellClickPath) {
            history.push(`${onCellClickPath}/${item.id}`);
          }
        };
        return (
          <CardViewEntry key={`${index}`}>
            <Card
              item={item}
              onCardClick={onCardClick}
              actionRendererProps={
                {
                  path,
                  options,
                  extraOptions: getExtraOptions
                    ? getExtraOptions(item, () => {})
                    : [],
                  handleDelete,
                  intl,
                  ecosystemId: item?.ecosystemId || item?.ecosystem || '',
                  appConfig,
                  fetchExtraOptions,
                } as ActionRendererProps
              }
            />
          </CardViewEntry>
        );
      })}
    </CardViewWrapper>
  ) : null;
};

export default CardView;
