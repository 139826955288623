import React, { FC } from 'react';
import { styled } from '@styles/themes';
import { DocumentType } from '@apps/documents/service';

const OrderPageLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    '. logoWithName'
    'supplier  ecosystemAddress'
    '.  referenceNumber'
    '.  creationDate'
    '.  creditorNumber'
    'documentType .'
    'documentNumber .'
    'freeTextStart .'
    'positions .'
    'freeTextEnd .'
    'deliveryAddress .'
    'deliveryTerms .'
    'paymentTerms .'
    'footer .';
`;

const docTypePageLayout: Record<DocumentType, any> = {
  order: OrderPageLayout,
};

const DefaultPageLayout: FC = ({ children }) => {
  return <div>{children}</div>;
};

const getPageLayout = (docType: DocumentType) => {
  const pageLayout = docTypePageLayout[docType];
  return pageLayout || DefaultPageLayout;
};

export default getPageLayout;
