import React, { CSSProperties, FC } from 'react';
import { IntlShape } from 'react-intl';
import { Form, Input } from 'antd';
const { Item } = Form;

type Props = {
  style?: CSSProperties;
  intl: IntlShape;
  forPdf: boolean;
  orderId?: string;
  disabled?: boolean;
};

const OrderId: FC<Props> = ({ style, intl, disabled = false }) => {
  return (
    <div style={style as CSSProperties}>
      <Item name="orderId">
        <Input
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'sales.orderId',
            defaultMessage: 'Order Id',
          })}
        />
      </Item>
    </div>
  );
};

export default OrderId;
