import React, { FC, useCallback } from 'react';
import { rootStore } from '@core/store';
import intl from '../../../../intl';
import { Provider } from 'react-redux';
import { RawIntlProvider } from 'react-intl';
import { useForm } from 'antd/lib/form/Form';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { Product } from '@apps/products/services';
import moment from 'moment';
import AppConfig from '@apps/purchases';
import FormContainer from '@components/FormContainer';
import SelectedEcosystem from '@components/SelectedEcosystem';
import {
  AppEntityForm,
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { emitCustomEvent } from '@core/services';
import { BrowserRouter } from 'react-router-dom';
import ProductName from '@apps/purchases/mainMenu/components/individualRequest/ProductName';
import ProductNumber from '@apps/purchases/mainMenu/components/individualRequest/ProductNumber';
import SelectedUnit from '@apps/purchases/mainMenu/components/individualRequest/SelectedUnit';
import NeedDate from '@apps/purchases/mainMenu/components/individualRequest/NeedDate';
import NeedAmount from '@apps/purchases/mainMenu/components/individualRequest/NeedAmount';
import Creator from '@apps/purchases/mainMenu/components/individualRequest/Creator';
import CreationDate from '@apps/purchases/mainMenu/components/individualRequest/CreationDate';
import Project from '@apps/purchases/mainMenu/components/individualRequest/Project';
import CostCenter from '@apps/purchases/mainMenu/components/individualRequest/CostCenter';

export type RequestFormModalProps = {
  initialValues: Partial<Product>;
  onSubmit: (individualRequest: IndividualPurchaseRequest) => void;
  onCancel?: () => void;
  user: User | null | undefined;
};

const RequestFormModal: FC<RequestFormModalProps> = ({
  onSubmit,
  user,
  initialValues: initialValuesProp,
  onCancel,
}) => {
  const [form] = useForm();
  const initialValues = {
    ...initialValuesProp,
    toBeStocked: true,
    productId: initialValuesProp.id,
    alreadyProduct: true,
    creator: user || '',
    needDate: null,
    creationDate: moment(),
  };

  const onFinishFailed = useCallback((errorInfo: ValidateErrorEntity) => {
    emitCustomEvent(ON_FINISH_FAILED_EVENT, {
      errorInfo,
    } as OnFinishFailedEvent);
  }, []);

  const handleSubmit = useCallback(
    (values: IndividualPurchaseRequest) => {
      if (user) {
        const payload: IndividualPurchaseRequest = {
          ...values,
          creator: user,
          toBeStocked: true,
          alreadyProduct: true,
          productId: initialValuesProp.id,
        };
        onSubmit(payload);
      }
    },
    [initialValuesProp.id, onSubmit, user],
  );

  return (
    <Provider store={rootStore}>
      <RawIntlProvider value={intl}>
        <BrowserRouter>
          <AppEntityForm
            autoComplete="off"
            initialValues={initialValues}
            appId={AppConfig.todixId}
            name="individualRequest"
            onSubmit={handleSubmit}
            onFinishFailed={onFinishFailed}
            onCancel={onCancel}
            readOnly={false}
            shouldRedirect={false}
            providedForm={form}
            hideButtons
          >
            {() => {
              return (
                <FormContainer>
                  <div>
                    <ProductName readOnly={true} />
                    <ProductNumber
                      readOnly={true}
                      action={'create-purchases-requests'}
                    />
                    <SelectedUnit
                      ecosystemId={initialValues?.ecosystem}
                      readOnly={true}
                    />
                    <NeedDate />
                    <NeedAmount readOnly={false} />
                  </div>
                  <div>
                    <SelectedEcosystem
                      action={'create-purchases-requests' as string}
                      disabled={true}
                      appId={AppConfig.todixId}
                    />
                    <Creator />
                    <CreationDate />
                    <Project readOnly={true} />
                    <CostCenter readOnly={true} />
                  </div>
                </FormContainer>
              );
            }}
          </AppEntityForm>
        </BrowserRouter>
      </RawIntlProvider>
    </Provider>
  );
};

export default RequestFormModal;
