import { firebase } from '@services/Firebase';
import { SUBSCRIPTIONS } from '@services/subscriptions/namespace';

const getSubscription = (
  id: string,
): Promise<Subscription | null> | undefined => {
  return firebase?.db
    ?.ref(`${SUBSCRIPTIONS}/${id}`)
    .once('value')
    .then((result) => {
      if (result.exists()) {
        const subId = result.key as string;
        const sub = result.val() as Subscription;
        if (sub.status === 'active' || sub.status === 'trialing') {
          return {
            ...sub,
            id: subId,
          };
        }
        return null;
      }
      return null;
    });
};

export default getSubscription;
