import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Button, Spin } from 'antd';
import moreIcon from './assets/more.svg';
import Modal from '@components/Modal';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { IntlShape, useIntl } from 'react-intl';
import { ActionOption } from '@components/AppView/index';
import { FileApp } from '@apps/AppTypes';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

export type ExtraOptions = MenuItemProps[];

export type ActionRendererProps = {
  path: string;
  options: ActionOption[];
  extraOptions?: ExtraOptions;
  fetchExtraOptions?: Promise<ExtraOptions>;
  handleDelete: () => void;
  intl: IntlShape;
  ecosystemId?: string;
  appConfig?: FileApp;
  icon?: React.ReactElement;
};

const getOverlay = ({
  path,
  options,
  extraOptions = [],
  handleDelete,
  intl,
}: ActionRendererProps) => {
  const optionsEntries: Record<string, string> = options.reduce((acc, it) => {
    return {
      ...acc,
      [it.action]: it.label,
    };
  }, {});
  return (
    <Menu>
      {optionsEntries.open && (
        <Menu.Item>
          <Link to={`${path}`}>{optionsEntries.open}</Link>
        </Menu.Item>
      )}
      {optionsEntries.edit && (
        <Menu.Item>
          <Link to={`${path}/edit`}>{optionsEntries.edit}</Link>
        </Menu.Item>
      )}
      {extraOptions.map((itemProps: MenuItemProps, index) => (
        <Menu.Item key={index} {...itemProps} />
      ))}
      {optionsEntries.delete && (
        <Menu.Item
          danger
          onClick={() => {
            Modal.confirm({
              content: intl.formatMessage({
                id: 'appview.delete.confirm',
                defaultMessage: 'Are you sure you want to remove this item?',
              }),
              onOk: handleDelete,
            });
          }}
        >
          {optionsEntries.delete}
        </Menu.Item>
      )}
    </Menu>
  );
};

export const ActionRenderer = ({
  path,
  options,
  extraOptions: passedExtraOptions = [],
  fetchExtraOptions,
  handleDelete,
  appConfig,
  ecosystemId,
  icon,
}: ActionRendererProps) => {
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [extraOptions, setExtraOptions] =
    useState<ExtraOptions>(passedExtraOptions);
  const intl = useIntl();
  const filteredExtraOptions = extraOptions;
  let canEdit = false;
  let canDelete = false;
  let filteredOptions = options;

  const handleVisibleChange = useCallback(
    (visible: boolean) => {
      if (visible) {
        if (fetchExtraOptions && !isFetched) {
          setFetchingOptions(true);
          fetchExtraOptions
            .then((fetchedOptions) => {
              setExtraOptions([...extraOptions, ...fetchedOptions]);
              setFetchingOptions(false);
              setIsFetched(true);
            })
            .catch((error) => {
              console.error(error);
              setFetchingOptions(false);
            });
        }
      }
    },
    [extraOptions, fetchExtraOptions, isFetched],
  );

  if (appConfig && ecosystemId) {
    const editAction = appConfig?.actions?.filter((action: string) =>
      action.includes('update'),
    )[0];
    const deleteAction = appConfig?.actions?.filter((action: string) =>
      action.includes('delete'),
    )[0];
    canEdit = checkUserPermissions(
      rootStore.getState(),
      appConfig.todixId,
      editAction || '',
      ecosystemId,
    );
    if (!canEdit) {
      filteredOptions = filteredOptions.filter((el) => el.action !== 'edit');
    }
    canDelete = checkUserPermissions(
      rootStore.getState(),
      appConfig.todixId,
      deleteAction || '',
      ecosystemId,
    );
    if (!canDelete) {
      filteredOptions = filteredOptions.filter((el) => el.action !== 'delete');
    }
  }

  const Icon = icon ? icon : <img src={moreIcon} alt="more" />;

  return (
    <Spin spinning={fetchingOptions}>
      <Dropdown
        overlay={getOverlay({
          path,
          options: filteredOptions,
          extraOptions: filteredExtraOptions,
          handleDelete,
          intl,
          appConfig,
        })}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          type="text"
          size="small"
          icon={
            filteredOptions.length || filteredExtraOptions?.length
              ? Icon
              : undefined
          }
        />
      </Dropdown>
    </Spin>
  );
};
