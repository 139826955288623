import React, { FC, useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Select, Space } from 'antd';
import { Contact, getContactsForEcosystem } from '@apps/contacts/services';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import WidgetResolver from '@components/WidgetResolver';

const { Item } = Form;

type Props = {
  onContactSet?(): void;
  disabled?: boolean;
  ecosystemId?: string;
  usedContactIds?: string[];
};

const ContactSearch: FC<Props> = ({
  onContactSet,
  disabled = false,
  ecosystemId,
  usedContactIds,
}) => {
  const intl = useIntl();
  const { getFieldValue, setFieldsValue, getFieldsValue } = useFormContext();
  const ecosystem = getFieldValue('ecosystem') || ecosystemId;

  const [contacts, setContacts] = useState<Contact[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ecosystem && contacts === null) {
      setLoading(true);
      getContactsForEcosystem(ecosystem, 'view-contacts')
        ?.then((results) => {
          if (usedContactIds) {
            const filteredContacts = results.filter(
              (el) => !usedContactIds.includes(el.id as string),
            );
            setContacts(filteredContacts);
          } else {
            setContacts(results);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contacts, ecosystem, usedContactIds]);

  const handleChange = useCallback(
    (id) => {
      if (disabled || contacts === null) {
        return;
      }
      const contact = contacts.find((contact) => contact.id === id);
      if (contact) {
        setFieldsValue({
          ...getFieldsValue(),
          relatedContactSnapshot: {
            ...contact,
            addresses: contact.addresses || [],
            emails: contact.emails || [],
            phones: contact.phones || [],
          },
        });
        if (onContactSet) {
          onContactSet();
        }
      }
    },
    [contacts, disabled, getFieldsValue, onContactSet, setFieldsValue],
  );

  const handleNewContactSubmit = useCallback(
    (contact: Contact) => {
      if (disabled) {
        return;
      }
      setContacts(contacts ? [contact, ...contacts] : [contact]);
      setFieldsValue({
        ...getFieldsValue(),
        relatedContact: contact.id,
        relatedContactSnapshot: {
          ...contact,
          addresses: contact.addresses || [],
          emails: contact.emails || [],
          phones: contact.phones || [],
        },
      });
      if (onContactSet) {
        onContactSet();
      }
    },
    [contacts, disabled, getFieldsValue, onContactSet, setFieldsValue],
  );

  const options =
    contacts?.map((contact, index) => {
      const address = contact?.addresses
        ? contact.addresses.find((add) => add.standard)
        : undefined;
      return {
        value: contact.id,
        'data-forsearch': `${contact?.companyName || ''} ${
          contact?.firstName || ''
        } ${contact?.lastName || ''}`,
        label: (
          <Space size="small" direction="vertical" key={index}>
            {contact.type === 'company' && <div>{contact.companyName}</div>}
            {contact.type === 'person' && (
              <div>
                {contact.firstName} {contact.lastName}
              </div>
            )}
            {address && (
              <div>
                {`${address.street || ''} ${address.number || ''} ${
                  address.zipcode || ''
                } ${address.city || ''} ${address.country || ''}`}
              </div>
            )}
          </Space>
        ),
      };
    }) || [];

  return (
    <div>
      <Item
        className="animate__animated animate__fadeIn animate__faster"
        name="relatedContact"
        required
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'sales.contact.error',
              defaultMessage: 'Missing contact',
            }),
          },
        ]}
      >
        <Select
          disabled={disabled}
          style={{ minWidth: '300px' }}
          showSearch={true}
          loading={loading}
          onChange={handleChange}
          filterOption={(inputValue, option) => {
            return option
              ? option['data-forsearch']
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              : false;
          }}
          placeholder={
            <FormattedMessage
              id="documentprocessing.step5.relatedcontact.label"
              defaultMessage="Related contact"
            />
          }
          options={options}
        />
      </Item>
      <WidgetResolver
        appName="contacts"
        widgetName="AddNewContactWidget"
        injectedProps={{
          OnClickEl: (props: any) => (
            <Button type="primary" {...props} disabled={disabled}>
              <FormattedMessage
                id="documentprocessing.step2.relatedcontact.header"
                defaultMessage="New contact"
              />
            </Button>
          ),
          onSubmit: (contact: Contact) =>
            !disabled && handleNewContactSubmit(contact),
        }}
      />
    </div>
  );
};

export default ContactSearch;
