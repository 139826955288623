import React, { CSSProperties, FC } from 'react';
import { IntlShape } from 'react-intl';
import { DocumentType } from '@apps/documents/service';

export type DocNumberProps = {
  style?: CSSProperties;
  intl: IntlShape;
  type: DocumentType;
  docNumber: string | number;
  forPdf: boolean;
};

const DocNumber: FC<DocNumberProps> = ({ intl, style, type, docNumber }) => {
  return (
    <div style={style as CSSProperties}>
      {intl.formatMessage({
        id: `${type}`,
        defaultMessage: type,
      })}
      {': '}
      {docNumber}
    </div>
  );
};

export default DocNumber;
