import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';

const addEcosystemData = (payload: ToolsProps): number => {
  const { props, doc, lastYPosition } = payload;
  const { ecosystem } = props;
  let newLastYPosition = lastYPosition;
  if (ecosystem && ecosystem.address) {
    const { name } = ecosystem;
    const { street, number, country, zipcode, city } = ecosystem.address;
    if (street && number !== undefined) {
      doc.setFont('times', 'normal', 'normal');
      doc.setFontSize(12);
      if (name) {
        doc.text(name, 150, newLastYPosition);
      }
      if (street && number) {
        newLastYPosition = newLastYPosition + 5;
        doc.text(`${street}, ${number}`, 150, newLastYPosition);
      }
      if (zipcode && city) {
        newLastYPosition = newLastYPosition + 5;
        doc.text(`${zipcode}, ${city}`, 150, newLastYPosition);
      }
      if (country) {
        newLastYPosition = newLastYPosition + 5;
        doc.text(country, 150, newLastYPosition);
      }
    }
  }
  return newLastYPosition;
};

export default addEcosystemData;
