import React, { FC, useCallback } from 'react';
import { ColumnType } from 'antd/lib/table';
import { PageViewElementProps } from '@apps/purchases/mainMenu/operationSteps/commonTypes';
import { useIntl } from 'react-intl';
import { BucketPosition } from '@apps/purchases/services/buckets';
import {
  CustomTable,
  PositionsContainer,
} from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/styles.sc';
import EditableRow from './EditableRow';
import EditableCell, { EditableBucketPosition } from './EditableCell';
import ExpandedRow from './ExpandedRow';
import { useFormContext } from '@components/FormWithReminder/useFormContext';

type PositionTableColumnType = ColumnType<any> & {
  editable?: boolean;
  dataIndex?: string;
  type?: 'dropdown' | 'number' | 'string' | 'unit' | 'price';
  ecosystem?: string;
  data?: BucketPosition;
};

const PositionsTable: FC<PageViewElementProps> = ({
  bucket,
  ecosystem,
  readOnly,
}) => {
  const intl = useIntl();
  const { getFieldValue, getFieldsValue, setFieldsValue } = useFormContext();

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const customColumns: PositionTableColumnType[] = [
    {
      title: intl.formatMessage({
        id: 'sales.createForm.position.label',
        defaultMessage: 'Pos.',
      }),
      render: (_v, _r, index) => {
        const pos = index + 1;
        return <span>{pos}</span>;
      },
    },
    {
      title: intl.formatMessage({
        id: 'products.columns.productname.header',
        defaultMessage: 'Product name',
      }),
      dataIndex: 'productName',
      type: 'string',
      editable: true,
    },
    {
      title: intl.formatMessage({
        id: 'products.form.productnumber.label',
        defaultMessage: 'Product number',
      }),
      dataIndex: 'productNumber',
      type: 'string',
      editable: true,
    },
    {
      title: intl.formatMessage({
        id: 'sales.createForm.amount.label',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'needAmount',
      editable: true,
      type: 'number',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.unit',
        defaultMessage: 'Unit',
      }),
      editable: true,
      type: 'unit',
      dataIndex: 'salesUnit',
      ecosystem,
    },
    {
      title: intl.formatMessage({
        id: 'sales.salesTab.unitAmount.label',
        defaultMessage: 'Price',
      }),
      editable: true,
      dataIndex: 'unitAmount',
      type: 'price',
      ecosystem,
    },
  ];
  const handleSave = useCallback(
    (row: EditableBucketPosition) => {
      const newData = [...getFieldValue('positions')];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
        isDirty: !row.isDirty
          ? item.unitAmount !== row.unitAmount ||
            item.unit !== row.unit ||
            item.description !== row.description
          : row.isDirty,
      });
      setFieldsValue({
        ...getFieldsValue(),
        positions: newData,
      });
    },
    [getFieldValue, getFieldsValue, setFieldsValue],
  );

  const columns = customColumns.map((col) => {
    return {
      ...col,
      onCell: (record: BucketPosition) => ({
        record,
        data: col.data,
        editable: col.editable,
        dataIndex: col.dataIndex,
        type: col.type,
        title: col.title,
        selectedEcosystem: col.ecosystem,
        handleSave,
        readOnly,
      }),
    };
  });

  return (
    <PositionsContainer>
      <CustomTable
        pagination={false}
        components={components}
        dataSource={bucket.positions}
        columns={columns as any}
        rowClassName={() =>
          'editable-row animate__animated animate__slideInUp animate__faster'
        }
        expandable={{
          expandedRowRender: (record: any, namePrefix: number) => (
            <ExpandedRow
              record={record}
              namePrefix={namePrefix}
              onBlur={handleSave}
            />
          ),
        }}
        bordered
      />
    </PositionsContainer>
  );
};

export default PositionsTable;
