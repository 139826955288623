import React, { CSSProperties, FC } from 'react';
import { Style } from '@react-pdf/types';
import { IntlShape } from 'react-intl';
import { Form, Input } from 'antd';
import { Text, View } from '@react-pdf/renderer';

const { Item } = Form;

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  referenceNumber?: string;
  disabled?: boolean;
};

const ReferenceNumber: FC<Props> = ({
  referenceNumber,
  forPdf,
  style,
  intl,
  disabled = false,
}) => {
  if (forPdf && referenceNumber) {
    return (
      <View style={style}>
        <Text>{referenceNumber}</Text>
      </View>
    );
  }

  return (
    <div style={style as CSSProperties}>
      <Item
        name="referenceNumber"
        label={intl.formatMessage({
          id: 'reference',
          defaultMessage: 'Reference',
        })}
        style={{ gridTemplateColumns: '1fr 1fr' }}
      >
        <Input disabled={disabled} />
      </Item>
    </div>
  );
};

export default ReferenceNumber;
