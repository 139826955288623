import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';

const addAvatar = (payload: ToolsProps): Promise<void> => {
  const { doc, lastYPosition, props } = payload;
  const { avatar } = props;
  if (avatar) {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = avatar;
      //let aspectHeight = 0;
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        const aspectRation = height / width;
        const canvas = document.createElement('canvas');
        const ctx = canvas?.getContext('2d');
        let dataURL = '';
        canvas.height = height;
        canvas.width = width;
        ctx?.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL('image/png');
        doc.addImage({
          imageData: dataURL,
          x: 150,
          y: lastYPosition,
          width: 24,
          height: aspectRation * 24,
        });
        resolve();
      };
    });
  } else {
    return Promise.resolve();
  }
};

export default addAvatar;
