import React, { CSSProperties, FC } from 'react';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import {
  ItemWithBottomMargin,
  TextAreaInput,
} from '@apps/purchases/settings/operationSteps/style.sc';
import { FormattedMessage } from 'react-intl';
import intl from '../../../../../intl';

type Props = TextAreaProps & {
  itemStyle?: CSSProperties;
  fieldName: string;
  fieldLabel?: string;
};

const FreeTextField: FC<Props> = ({
  fieldName,
  fieldLabel,
  itemStyle,
  ...rest
}) => {
  return (
    <ItemWithBottomMargin
      name={fieldName}
      label={fieldLabel}
      style={itemStyle}
      rules={[
        {
          type: 'string',
          max: 115,
          message: (
            <FormattedMessage
              id="sales.operations.steps.limit"
              defaultMessage="This part can't be longer than 115 characters or more than 5 lines"
            />
          ),
          validator: (_rule, value) => {
            if (value?.split('\n').length > 5) {
              return Promise.reject(
                intl.formatMessage({
                  id: 'sales.operations.steps.limit',
                  defaultMessage:
                    "This part can't be longer than 115 characters or more than 5 lines",
                }),
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <TextAreaInput {...rest} />
    </ItemWithBottomMargin>
  );
};

export default FreeTextField;
