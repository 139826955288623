import { styled } from '@styles/themes';
import { Modal } from 'antd';
import TodixModal from '@components/Modal';

export const ProductModal = styled(Modal)`
  top: 24px;

  .form-with-reminder-buttons {
    position: relative !important;
    display: flex;
    justify-content: end;
  }

  & {
    .ant-modal-close-x {
      width: 28px !important;
      height: 28px !important;
      line-height: 28px !important;
    }
  }
`;

export const RequestModal = styled(TodixModal)`
  && {
    .ant-modal-content {
      width: 50vw !important;
    }
  }
`;

export const Italic = styled.div`
  font-style: italic;
`;

export const TwoColumnGridProductCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;

  & {
    .image-gallery-image {
      max-height: 50vh !important;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProductStockEntries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;
