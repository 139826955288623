import moment from 'moment';
import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';

const addDocumentData = (payload: ToolsProps) => {
  const { props, lastYPosition, doc } = payload;
  const { intl, values } = props;
  const { referenceNumber, creationDate } = values;
  let newLastPositionY = lastYPosition;
  const posX = 140;
  if (referenceNumber) {
    const reference = `${intl.formatMessage({
      id: `reference`,
      defaultMessage: 'Reference',
    })}: ${referenceNumber}`;
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(reference, posX, newLastPositionY);
    newLastPositionY = newLastPositionY + 5;
  }
  if (creationDate) {
    const dateOfCreation = `${intl.formatMessage({
      id: `date`,
      defaultMessage: 'Date',
    })}: ${moment(creationDate).format('DD.MM.YYYY')}`;
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(dateOfCreation, posX, newLastPositionY);
    newLastPositionY = newLastPositionY + 5;
  }
  return newLastPositionY;
};

export default addDocumentData;
