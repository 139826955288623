import React, { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import {
  getIndividualPurchaseRequest,
  updateIndividualPurchaseRequest,
} from '@apps/purchases/services/individualPurchaseRequests';
import IndividualRequest from '@apps/purchases/mainMenu/components/individualRequest';
import AppConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { Spin } from 'antd';
import { createActivity } from '@core/services';

const ViewIndividualRequest: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [request, setRequest] = useState<IndividualPurchaseRequest | null>(
    null,
  );

  useEffect(() => {
    if (id) {
      getIndividualPurchaseRequest(id).then(setRequest);
    }
  }, [id]);

  if (!request) return null;

  const canProceed = request?.ecosystem
    ? checkUserPermissions(
        rootStore.getState(),
        AppConfig.todixId,
        'update-purchases-requests' as PURCHASES_ACTION,
        request.ecosystem,
      )
    : false;

  const handleSubmit = async (values: IndividualPurchaseRequest) => {
    if (canProceed) {
      try {
        setIsSubmitting(true);
        await updateIndividualPurchaseRequest(id, values);
        await createActivity({
          ecosystemId: values.ecosystem,
          userId: values.creator.uid,
          title: 'purchase.request.updated.by',
          content: values.creator.displayName,
        });
        setIsSubmitting(false);
        history.push(`/app/${AppConfig.todixId}/banf_list`);
      } catch (e) {
        console.error(e);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Spin spinning={isSubmitting}>
      <IndividualRequest
        readOnly={!canProceed || request?.status === 'draft'}
        initialValues={{
          ...request,
        }}
        action={'view-purchases-requests'}
        cancelLink={`/app/${AppConfig.todixId}/banf_list`}
        backLink={`/app/${AppConfig.todixId}/banf_list`}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};

export default ViewIndividualRequest;
