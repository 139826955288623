import React, { CSSProperties, FC } from 'react';
import { DatePicker, Form } from 'antd';
import { Style } from '@react-pdf/types';
import { IntlShape } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

const { Item } = Form;

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  creationDate?: any;
  disabled?: boolean;
};

const CreationDate: FC<Props> = ({ forPdf, creationDate, intl, style }) => {
  if (forPdf && creationDate) {
    return (
      <View style={style}>
        <Text>{creationDate}</Text>
      </View>
    );
  }

  return (
    <div style={style as CSSProperties}>
      <Item
        name="creationDate"
        label={intl.formatMessage({
          id: 'date',
          defaultMessage: 'Date',
        })}
        style={{ gridTemplateColumns: '1fr 1fr' }}
      >
        <DatePicker disabled={true} />
      </Item>
    </div>
  );
};

export default CreationDate;
