import React, { FC } from 'react';
import { Form, DatePicker } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { DatePickerProps } from 'antd/lib/date-picker';

const { Item } = Form;

type Props = FormItemProps & {
  datePickerProps?: DatePickerProps;
};

const StyledDatePickerItem: FC<Props> = ({ datePickerProps, ...rest }) => {
  return (
    <Item {...rest}>
      {datePickerProps ? <DatePicker {...datePickerProps} /> : <DatePicker />}
    </Item>
  );
};

export default StyledDatePickerItem;
