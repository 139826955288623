import { Card } from 'antd';
import { styled } from '@styles/themes';

export const GridTwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
`;

export const GridTwoColumnsBigSmall = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-column-gap: 20px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BigBold = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const ItalicSmall = styled.div`
  font-size: 10px;
  font-style: italic;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexCenterGray = styled.div`
  display: flex;
  justify-content: center;
  background: rgb(240 242 245);
`;

export const PricePreviewHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #434343;
`;

export const PriceSegmentCard = styled(Card)`
  & {
    .ant-card-head-title {
      padding: 8px 0;
    }
    .ant-card-head {
      min-height: 16px;
    }
  }
`;

export const TaxContainer = styled.div`
  display: flex;
  gap: 20px;
`;
