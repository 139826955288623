import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';

const addTerms = (payload: ToolsProps): number => {
  const { doc, lastYPosition, props } = payload;
  let newYPosition = lastYPosition;

  const deliveryTermsLabel = `${props.intl.formatMessage({
    id: 'sales.settings.deliveryterms.header',
    defaultMessage: 'Delivery terms',
  })}:`;

  const paymentTermsLabel = `${props.intl.formatMessage({
    id: 'sales.settings.paymentTerms.header',
    defaultMessage: 'Payment terms',
  })}:`;

  doc.setFont('times', 'normal', 700);
  doc.setFontSize(12);
  doc.text(deliveryTermsLabel, 20, newYPosition);
  newYPosition = newYPosition + 15;

  if (props.values.deliveryTerms && props.values.deliveryTerms?.shortage) {
    doc.setFont('times', 'normal', 'normal');
    const splitText = doc.splitTextToSize(
      props.values.deliveryTerms.shortage,
      180,
    );
    doc.text(splitText, 20, newYPosition);
  }
  newYPosition = newYPosition + 15;
  doc.setFont('times', 'normal', 700);
  doc.text(paymentTermsLabel, 20, newYPosition);

  if (props.values.paymentTerms && props.values.paymentTerms?.name) {
    newYPosition = newYPosition + 15;
    doc.setFont('times', 'normal', 'normal');
    const splitText = doc.splitTextToSize(props.values.paymentTerms.name, 180);
    doc.text(splitText, 20, newYPosition);
  }

  return newYPosition;
};

export default addTerms;
