import React, { FC, useState, useCallback } from 'react';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { ProductContactConstraint } from '@apps/products/services';
import { useForm } from 'antd/lib/form/Form';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Card } from '@todix/ui-components';
import Tabs from '@components/Tabs';
import intl from '../../../../../intl';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import SimpleProductNumber from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductNumber';
import SimpleProductName from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductName';
import SelectedUnit from '@apps/products/mainMenu/components/SelectedUnit';
import { Button, Form, message } from 'antd';
import Supplier from '@apps/purchases/mainMenu/individualRequestsList/Supplier';
import Price from '@apps/purchases/mainMenu/individualRequestsList/Price';
import NeedAmount from '@apps/purchases/mainMenu/components/individualRequest/NeedAmount';
import NeedDate from '@apps/purchases/mainMenu/components/individualRequest/NeedDate';
import moment from 'moment';
import {
  ButtonContainerModal,
  PushToInnerContainer,
  TopContainerPushTo,
} from '@apps/purchases/mainMenu/components/styles.sc';
import PurchaseStep from '@apps/purchases/mainMenu/components/PurchaseStep';
import {
  Bucket,
  BucketPosition,
  BucketStep,
  createBucket,
  getBucketsPerDetails,
  updateBucket,
} from '@apps/purchases/services/buckets';
import { ListEvents } from '@components/List';
import {
  createIndividualPurchaseRequest,
  updateIndividualPurchaseRequest,
} from '@apps/purchases/services/individualPurchaseRequests';
import ExpectedDeliveryDate from '@apps/purchases/mainMenu/individualRequestsList/ExpectedDeliveryDate';
import DescriptionWithIndicator from '@apps/purchases/mainMenu/individualRequestsList/DescriptionWithIndicator';

const { TabPane } = Tabs;

export type PushToEventName = 'pushTo';

export type PushToEventPayload = {
  request: IndividualPurchaseRequest;
  constraints: ProductContactConstraint[];
  allowingTypes: BucketStep[];
};

type PushToProps = {};

const PushTo: FC<PushToProps> = () => {
  const [form] = useForm();
  const [request, setRequest] = useState<IndividualPurchaseRequest | null>(
    null,
  );
  const [show, setShow] = useState(false);
  const [constraints, setConstraints] = useState<ProductContactConstraint[]>(
    [],
  );
  const [constraintPrice, setConstraintPrice] = useState<number | null>(null);
  const [bucket, setBucket] = useState<Bucket | null>(null);
  const [allowingTypes, setAllowingTypes] = useState<BucketStep[]>([]);

  useCustomEventListener<PushToEventName, PushToEventPayload>(
    'pushTo',
    async (data) => {
      if (data && data.request && data.constraints && data.allowingTypes) {
        setAllowingTypes(data.allowingTypes);
        setRequest(data.request);
        setConstraints(data.constraints);
        if (data.constraints) {
          const defaultConstraint = data.constraints.find((el) => el.isDefault);
          const selectedConstraint = defaultConstraint || data.constraints[0];
          if (selectedConstraint) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              productNumber: data.request.productNumber,
              productName: data.request.productName,
              productId: data.request.productId,
              relatedContact: selectedConstraint.relatedContact,
              relatedContactSnapshot: selectedConstraint.relatedContactSnapshot,
              unit: selectedConstraint.unit,
              unitAmount: selectedConstraint.unitAmount,
              currency: selectedConstraint.currency,
              standardDeliveryTime: selectedConstraint.standardDeliveryTime,
              description: data.request.description,
              stepType: data.allowingTypes[0],
              needDate: data.request?.needDate
                ? moment(data.request?.needDate)
                : null,
            });

            if (selectedConstraint.relatedContact && data.request.ecosystem) {
              //find and set related bucket
              const openedBuckets = await getBucketsPerDetails({
                status: data.request?.status || 'draft',
                stepType: data.request?.stepType || 'ordered',
                relatedContact: selectedConstraint.relatedContact,
                ecosystem: data.request?.ecosystem as string,
              });

              if (openedBuckets.length) {
                setBucket(openedBuckets[0]);
              } else {
                setBucket(null);
              }
              setShow(true);
            }
          }
        }
      }
    },
  );

  const onSubmit = useCallback(
    async (values: any) => {
      if (!request) {
        return;
      }
      const bucketPosition: BucketPosition = {
        creationDate: request.creationDate,
        productName: values.productName,
        productNumber: values.productNumber,
        needAmount: values.needAmount,
        needDate: values.needDate,
        expectedDeliveryDate: values.expectedDeliveryDate,
        currency: 'EUR',
        unitAmount: values.unitAmount,
        ecosystem: request.ecosystem as string,
        creator: request.creator,
        productId: request.productId,
        description: values.description || '',
        shortDescription: request?.shortDescription || '',
        unit: values.unit,
        id: request?.id,
      };
      if (bucket && request) {
        //update bucket (add position)
        try {
          let requestId = request.id;
          if (requestId) {
            await updateIndividualPurchaseRequest(requestId, {
              ...request,
              bucketId: bucket.id as string,
              status: bucket.status,
              stepType: bucket.stepType,
            });
          } else {
            requestId = await createIndividualPurchaseRequest({
              ...request,
              ...bucketPosition,
              bucketId: bucket.id as string,
              status: bucket.status,
              stepType: bucket.stepType,
            });
          }
          const updatedBucket = bucket;
          updatedBucket.positions = [
            ...updatedBucket.positions,
            {
              ...bucketPosition,
              id: requestId,
            },
          ];
          await updateBucket(bucket.id as string, updatedBucket);
          emitCustomEvent<ListEvents>('refreshList');
          message.success(
            intl.formatMessage({
              id: 'bucket.updated',
              defaultMessage: 'Bucket has been updated',
            }),
          );
          setShow(false);
        } catch (e) {
          console.error(e);
          message.error(
            intl.formatMessage({
              id: 'error.request',
              defaultMessage: 'Error while processing request',
            }),
          );
        }
      } else {
        //create a new one
        const newBucket: Bucket = {
          draftSaved: false,
          type: 'supplier',
          status: 'draft',
          relatedContact: values.relatedContact,
          relatedContactSnapshot: values.relatedContactSnapshot,
          stepType: values.stepType,
          ecosystem: request?.ecosystem as string,
          positions: [bucketPosition],
        };
        try {
          const bucketId = await createBucket(newBucket);
          let requestId = request.id;
          if (requestId) {
            await updateIndividualPurchaseRequest(requestId, {
              ...request,
              bucketId: bucketId,
              status: newBucket.status,
              stepType: newBucket.stepType,
            });
          } else {
            requestId = await createIndividualPurchaseRequest({
              ...request,
              ...bucketPosition,
              bucketId: bucketId,
              status: newBucket.status,
              stepType: newBucket.stepType,
            });
            const updatedBucket = newBucket;
            updatedBucket.positions = [
              {
                ...bucketPosition,
                id: requestId,
              },
            ];
            await updateBucket(bucketId as string, updatedBucket);
          }
          emitCustomEvent<ListEvents>('refreshList');
          setShow(false);
        } catch (e) {
          console.error(e);
          message.error(
            intl.formatMessage({
              id: 'error.request',
              defaultMessage: 'Error while processing request',
            }),
          );
        }
      }
    },
    [bucket, request],
  );

  const handleStepTypeSelect = useCallback(
    async (stepType: BucketStep) => {
      const relatedContact = form.getFieldValue('relatedContact');
      const openedBuckets = await getBucketsPerDetails({
        status: 'draft',
        stepType,
        relatedContact,
        ecosystem: request?.ecosystem as string,
      });

      if (openedBuckets.length) {
        setBucket(openedBuckets[0]);
      } else {
        setBucket(null);
      }
    },
    [form, request?.ecosystem],
  );

  const handleSupplierSet = useCallback(async () => {
    const supplierId = form.getFieldValue('relatedContact');
    const constraint = constraints.find(
      (el) => el.relatedContact === supplierId,
    );
    if (constraint) {
      const formValues = form.getFieldsValue();
      form.setFieldsValue({
        ...formValues,
        unitAmount: constraint.unitAmount,
        unit: constraint.unit,
        currency: constraint.currency,
        standardDeliveryTime: constraint.standardDeliveryTime,
        expectedDeliveryDate: null,
      });
      setConstraintPrice(constraint.unitAmount);
      //find and set related bucket
      const openedBuckets = await getBucketsPerDetails({
        status: 'draft',
        stepType: formValues.stepType,
        relatedContact: supplierId,
        ecosystem: request?.ecosystem as string,
      });

      if (openedBuckets.length) {
        setBucket(openedBuckets[0]);
      } else {
        setBucket(null);
      }
    }
  }, [form, constraints, request?.ecosystem]);

  const initialValues = {
    ...request,
    needDate: request?.needDate ? moment(request?.needDate) : null,
    expectedDeliveryDate: null,
  };

  if (!request) {
    return null;
  }

  return (
    <DraggableModalProvider>
      <DraggableModal
        confirmLoading={true}
        visible={show}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setShow(false);
        }}
      >
        <Card actions={[<div />]}>
          <Tabs defaultActiveKey="1">
            <TabPane
              key="1"
              tab={intl.formatMessage({
                id: `push.to`,
                defaultMessage: 'Push to...',
              })}
            >
              <AppEntityForm
                appId={''}
                autoComplete="off"
                initialValues={initialValues}
                name="pushTo"
                providedForm={form}
                readOnly={false}
                onSubmit={onSubmit}
                hideButtons={true}
              >
                {() => {
                  return (
                    <PushToInnerContainer>
                      <Form.Item name="relatedContactSnapshot" hidden />
                      <TopContainerPushTo>
                        <PurchaseStep
                          allowingTypes={allowingTypes}
                          fieldName="stepType"
                          onStepTypeSelect={handleStepTypeSelect}
                        />
                        <Supplier
                          constraints={constraints}
                          onContactSet={handleSupplierSet}
                        />
                      </TopContainerPushTo>
                      <SimpleProductNumber />
                      <SimpleProductName />
                      <Price constraintPrice={constraintPrice} />
                      <SelectedUnit ecosystemId={request?.ecosystem} />
                      <NeedAmount readOnly={false} />
                      <NeedDate />
                      <ExpectedDeliveryDate />
                      <DescriptionWithIndicator
                        description={request?.description || ''}
                      />
                      <ButtonContainerModal>
                        <Button type="primary" htmlType="submit">
                          {intl.formatMessage({
                            id: 'push',
                            defaultMessage: 'Push',
                          })}
                        </Button>
                      </ButtonContainerModal>
                    </PushToInnerContainer>
                  );
                }}
              </AppEntityForm>
            </TabPane>
          </Tabs>
        </Card>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default PushTo;
