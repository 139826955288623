import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Form, Select, Space } from 'antd';
import { getDocumentsTypesSettings } from '@apps/documents/service';
import { emitCustomEvent } from '@core/services';
import StyledItemForm from '@apps/documents/mainMenu/components/StyledItemForm';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { DocumentType } from '@apps/documents/service/namespace';
import stepTypeIconMapper from '@apps/purchases/mainMenu/stepTypeIconMapper';
import {
  PurchaseOperation,
  PurchaseOperationStatus,
} from '@apps/purchases/services/purchaseOperation';

const { Item } = Form;

type StepTypeProps = {
  ecosystem: string;
  fieldName?: string;
  allowingTypes?: DocumentType[];
  relatedSteps: PurchaseOperation[];
};

const stepTypes = Object.keys(stepTypeIconMapper);

const colors: Record<PurchaseOperationStatus, string> = {
  success: '#0e7d40',
  error: '#f5222d',
  empty: '#595959',
  pending: '#1890ff',
  cancelled: '#faad14',
};

export const StepType: FC<StepTypeProps> = ({
  ecosystem,
  allowingTypes,
  relatedSteps = [],
  fieldName = 'type',
}) => {
  const intl = useIntl();
  const { readOnly } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<string[]>([]);

  const onSelect = useCallback((type) => {
    emitCustomEvent('setPurchaseStep', {
      type,
    });
  }, []);

  useEffect(() => {
    if (ecosystem) {
      setLoading(true);
      getDocumentsTypesSettings(ecosystem)
        ?.then((results) => {
          if (results) {
            const documentTypes = results.docTypes
              .filter((entry) => entry.isActive)
              .map((entry) => entry.docType)
              .concat([
                'supplierOrderConfirmation',
                'upfrontInvoice',
                'incomingDeliveryNote',
                'goodsReciept',
                'deliveryReminder',
              ]);
            const filteredStepTypes = stepTypes.filter((type) =>
              documentTypes.includes(type),
            );
            setSteps(filteredStepTypes);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [ecosystem]);

  return (
    <StyledItemForm
      name={fieldName}
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'sales.select.operation.step.error',
            defaultMessage: 'Missing operation step',
          }),
        },
      ]}
    >
      <Select
        showSearch
        disabled={readOnly}
        optionFilterProp="label"
        placeholder={intl.formatMessage({
          id: `sales.select.operation.step`,
          defaultMessage: 'Select operation step...',
        })}
        onSelect={onSelect}
        loading={loading}
        filterOption={(input, option) => {
          return option
            ? option['data-forsearch']
                .toLowerCase()
                .includes(input.toLowerCase())
            : false;
        }}
        options={steps
          .filter((docType) =>
            allowingTypes?.length
              ? allowingTypes.includes(docType as unknown as DocumentType)
              : true,
          )
          .map((type) => {
            const Icon: FC<{ style: CSSProperties }> = stepTypeIconMapper[type];
            const step = relatedSteps.find((entry) => entry.step === type);
            const status = step ? step.status : 'empty';
            const color = colors[status];
            return {
              label: (
                <Space>
                  <div
                    style={{
                      backgroundColor: color || '#8c8c8c',
                      padding: '2px',
                    }}
                  >
                    <Icon style={{ color: '#fff' }} />
                  </div>
                  <div>
                    {intl.formatMessage({
                      id: `${type}`,
                      defaultMessage: type,
                    })}
                  </div>
                </Space>
              ),
              'data-forsearch': `${intl.formatMessage({
                id: `${type}`,
                defaultMessage: type,
              })}`,
              value: type,
            };
          })}
      />
    </StyledItemForm>
  );
};

type Props = {
  allowingTypes?: DocumentType[];
  relatedSteps: PurchaseOperation[];
};

export default ({ allowingTypes, relatedSteps }: Props) => {
  return (
    <Item shouldUpdate={true} noStyle>
      {({ getFieldValue }) => {
        const ecosystem = getFieldValue('ecosystem');
        return (
          <StepType
            ecosystem={ecosystem}
            allowingTypes={allowingTypes}
            relatedSteps={relatedSteps}
          />
        );
      }}
    </Item>
  );
};
