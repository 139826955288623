import React, { useState, useEffect } from 'react';
import intl from '../../../../../intl';
import MoneyInput from '@components/MoneyInput';
import { Form, Tooltip } from 'antd';
import { ModifiedIndicator } from '@apps/purchases/mainMenu/components/ProductsWithPrices/styles.sc';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PriceContainer } from '@apps/purchases/mainMenu/components/styles.sc';
const { Item } = Form;

type PriceProps = {
  constraintPrice: number | null;
};

const Price: React.FC<PriceProps> = ({ constraintPrice }) => {
  const [price, setPrice] = useState<number | null>(constraintPrice);
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    if (constraintPrice === price) {
      setShowIndicator(false);
    }
  }, [constraintPrice, price]);

  useEffect(() => {
    if (constraintPrice !== price) {
      setPrice(constraintPrice);
    }
  }, [constraintPrice, price]);

  return (
    <PriceContainer>
      <Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const currency = getFieldValue('currency');
          return (
            <Item
              name="unitAmount"
              label={intl.formatMessage({
                id: 'sales.salesTab.unitAmount.label',
                defaultMessage: 'Price',
              })}
              required
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'sales.salesTab.unitAmount.validationError',
                    defaultMessage: 'Please enter price',
                  }),
                },
              ]}
            >
              <MoneyInput currency={currency} />
            </Item>
          );
        }}
      </Item>
      <Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const formPrice = getFieldValue('unitAmount');

          setShowIndicator(`${formPrice}` !== `${price}` && price !== null);

          return (
            showIndicator && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'price.changed',
                  defaultMessage: 'Price has been changed',
                })}
              >
                <ModifiedIndicator
                  icon={<ExclamationCircleOutlined />}
                  color="warning"
                />
              </Tooltip>
            )
          );
        }}
      </Item>
    </PriceContainer>
  );
};

export default Price;
