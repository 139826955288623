import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';

const addTextField = (text: string, payload: ToolsProps) => {
  const { doc, lastYPosition } = payload;
  doc.setFont('times', 'normal', 'normal');
  doc.setFontSize(12);
  const splitText = doc.splitTextToSize(text, 180);
  doc.text(splitText, 20, lastYPosition);
};

export default addTextField;
