import React, { FC, useState, useCallback } from 'react';
import {
  getProductContactConstraints,
  getProductPurchasePrices,
  ProductContactConstraint,
  ProductPurchasePrice,
  updateProductContactConstraint,
} from '@apps/products/services';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import { Spin, Form, Button } from 'antd';
import { Card } from '@todix/ui-components';
import { AppProductContactEvent } from '@apps/products/mainMenu/components/DetailedProduct/getExtraOptions';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { useForm } from 'antd/lib/form/Form';
import SimpleProductNumber from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductNumber';
import SimpleProductName from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductName';
import intl from '../../../../../../intl';
import SelectedUnit from '@apps/products/mainMenu/components/SelectedUnit';
import StandardDeliveryTime from '@apps/products/mainMenu/components/DetailedProduct/StandardDeliveryTime';
import UnitAmount from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/price/UnitAmount';
// import Currency from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/price/Currency';
import ContactSearch from '@apps/sales/mainMenu/components/SaleCreator/components/ContactSearch';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import Tabs from '@components/Tabs';
import { createProductContactConstraint } from '@apps/products/services';
import IsDefault from '@apps/products/mainMenu/components/DetailedProduct/IsDefault';

const { TabPane } = Tabs;

export type RefreshConstraintEvent = {
  type: ProductContactConstraint['type'];
};

const AddProductContactConstraint: FC = () => {
  const [form] = useForm();
  const [show, setShow] = useState(false);
  const [type, setType] = useState<ProductContactConstraint['type'] | null>(
    null,
  );
  const [constraints, setConstraints] = useState<ProductContactConstraint[]>(
    [],
  );
  const [constraint, setConstraint] = useState<ProductContactConstraint | null>(
    null,
  );
  const [oldConstraints, setOldConstraints] = useState<
    ProductContactConstraint[]
  >([]);
  const [prices, setPrices] = useState<ProductPurchasePrice[]>([]);
  const [ecosystemId, setEcosystemId] = useState<string>('');
  const [productId, setProductId] = useState<string>('');

  useCustomEventListener<'editSupplier', ProductContactConstraint>(
    'editSupplier',
    async (data) => {
      if (data) {
        try {
          const fetchedConstraints = await getProductContactConstraints({
            ecosystemId: data.ecosystemId,
            productId: data.productId,
            type: 'supplier',
          });
          if (fetchedConstraints) {
            setOldConstraints(fetchedConstraints);
          }
          const fetchedPrices = await getProductPurchasePrices({
            ecosystemId: data.ecosystemId,
            productId: data.productId,
            skipArchived: true,
          });
          if (fetchedPrices) {
            setPrices(fetchedPrices);
          }
          form.setFieldsValue({ ...data });
          setConstraint(data);
          setConstraints([]);
          setEcosystemId(data.ecosystemId);
          setType('supplier');
          setProductId(data.productId);
          setShow(true);
        } catch (error) {
          console.error(error);
        }
      }
    },
  );

  useCustomEventListener<'addSupplier', AppProductContactEvent>(
    'addSupplier',
    async (data) => {
      if (data) {
        if (data.productId && data.ecosystemId) {
          try {
            setConstraint(null);

            const { ecosystemId, productId } = data;
            const fetchedConstraints = await getProductContactConstraints({
              ecosystemId,
              productId,
              type: 'supplier',
            });
            if (fetchedConstraints) {
              setConstraints(fetchedConstraints);
            }
            const fetchedPrices = await getProductPurchasePrices({
              ecosystemId,
              productId,
              skipArchived: true,
            });
            if (fetchedPrices) {
              setPrices(fetchedPrices);
            }
            setEcosystemId(ecosystemId);
            setType('supplier');
            setProductId(productId);
            setOldConstraints([]);
            form.setFieldsValue({
              relatedContactSnapshot: null,
              relatedContact: '',
              ecosystemId,
              productId,
              type: 'supplier',
              productName: '',
              productNumber: '',
              unitAmount:
                fetchedPrices?.find((el) => el.isDefaultPrice)?.unitAmount ||
                undefined,
              isDefault: false,
              standardDeliveryTime: null,
            });
            setShow(true);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  );

  useCustomEventListener<'addCustomer', AppProductContactEvent>(
    'addCustomer',
    (data) => {
      if (data) {
        if (data.productId && data.ecosystemId) {
          getProductContactConstraints({
            ecosystemId: data.ecosystemId,
            productId: data.productId,
            type: 'customer',
          })
            ?.then(setConstraints)
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setEcosystemId(data.ecosystemId);
              setType('customer');
              setProductId(data.productId);
              setShow(true);
            });
        }
      }
    },
  );

  const onSubmit = useCallback(
    async (values: ProductContactConstraint) => {
      if (type) {
        if (constraint) {
          await updateProductContactConstraint(constraint.id as string, {
            ...values,
            isDefault: values.isDefault,
          });
          if (values.isDefault) {
            const oldDefault = oldConstraints
              .filter((el) => el.id !== constraint.id)
              .find((el) => el.isDefault);
            if (oldDefault && oldDefault.id) {
              await updateProductContactConstraint(oldDefault.id, {
                ...oldDefault,
                isDefault: false,
              });
            }
          }
        } else {
          await createProductContactConstraint({
            ...values,
            isDefault: values.isDefault,
          });
          if (values.isDefault) {
            const oldDefault = constraints.find((el) => el.isDefault);
            if (oldDefault && oldDefault.id) {
              await updateProductContactConstraint(oldDefault.id, {
                ...oldDefault,
                isDefault: false,
              });
            }
          }
        }

        form.resetFields();
        setShow(false);
        setConstraint(null);
        setConstraints([]);
        emitCustomEvent<'refreshConstraint', RefreshConstraintEvent>(
          'refreshConstraint',
          {
            type,
          },
        );
      }
    },
    [type, constraint, form, oldConstraints, constraints],
  );

  if (!productId || !type || !ecosystemId) {
    return null;
  }

  const initialValues: Partial<ProductContactConstraint> = constraint
    ? constraint
    : {
        productId,
        type,
        ecosystemId,
        unitAmount:
          prices.find((el) => el.isDefaultPrice)?.unitAmount || undefined,
        isDefault: false,
      };

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={show}
        footer={null}
        onCancel={() => {
          setConstraint(null);
          form.resetFields();
          setShow(false);
          emitCustomEvent<'refreshTab', any>('refreshTab', {
            type,
          });
        }}
        destroyOnClose
      >
        <Spin spinning={false}>
          <Card>
            <Tabs defaultActiveKey="1">
              <TabPane
                key="1"
                tab={intl.formatMessage({
                  id: `${type}`,
                  defaultMessage: `${type}`,
                })}
              >
                <AppEntityForm
                  appId={''}
                  autoComplete="off"
                  initialValues={initialValues}
                  name="productContactConstraint"
                  providedForm={form}
                  readOnly={false}
                  onSubmit={onSubmit}
                  hideButtons={true}
                >
                  {() => {
                    return (
                      <div>
                        <Form.Item name="productId" hidden />
                        <Form.Item name="type" hidden />
                        <Form.Item name="ecosystemId" hidden />
                        <Form.Item name="relatedContactSnapshot" hidden />
                        <ContactSearch
                          disabled={constraint !== null}
                          ecosystemId={ecosystemId}
                          usedContactIds={constraints.map(
                            (el) => el.relatedContact,
                          )}
                        />
                        <SimpleProductName />
                        <SimpleProductNumber />
                        <UnitAmount />
                        <SelectedUnit ecosystemId={ecosystemId} />
                        <StandardDeliveryTime />
                        {type === 'supplier' && (
                          <IsDefault
                            disabled={
                              oldConstraints.filter((el) => el.isDefault)
                                .length <= 2 && constraint?.isDefault
                            }
                          />
                        )}
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            form="productContactConstraint"
                          >
                            {constraint
                              ? intl.formatMessage({
                                  id: 'button.save',
                                  defaultMessage: 'Save',
                                })
                              : intl.formatMessage({
                                  id: 'create.constraint',
                                  defaultMessage: 'Create constraint',
                                })}
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </AppEntityForm>
              </TabPane>
            </Tabs>
          </Card>
        </Spin>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default AddProductContactConstraint;
