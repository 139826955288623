import React, { FC, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { Document } from '@apps/documents/service';
import { Card } from '@todix/ui-components';
import Tabs from '@components/Tabs';
import { TabsWithOverflowHidden } from '@apps/sales/mainMenu/styles.sc';
import { DashboardOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import {
  Bucket,
  getBucket,
  getDocumentsRelatedWithBucket,
} from '@apps/purchases/services/buckets';
import { CompanyContact, Contact } from '@apps/contacts/services';
import AppConfig from '@apps/purchases';
import DocumentAppConfig from '@apps/documents';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import getArchivedDocumentsTab from '@apps/documents/mainMenu/widgets/ArchivedDocumentsTab';
import BucketCreator from '@apps/purchases/mainMenu/operationSteps/operationStep/BucketCreator';

const { TabPane } = Tabs;

const OperationStep: FC = () => {
  const { id } = useParams<{ id: string; appId: string }>();
  const [relatedDocuments, setRelatedDocuments] = useState<Document[]>([]);
  const [activeKey, setActiveKey] = useState('1');
  const [isProcessing, setIsProcessing] = useState(false);
  const [ecosystemId, setEcosystemId] = useState('');
  const fullEcosystem = useSelector(getEcosystemById(ecosystemId)) as Ecosystem;
  const [bucket, setBucket] = useState<Bucket | null>(null);
  const contact = bucket?.relatedContactSnapshot as Contact;
  let contactName = '';
  if (contact?.type === 'person') {
    contactName = `${contact?.firstName || ''} ${contact?.lastName || ''}`;
  }
  if (contact?.type === 'company') {
    contactName = `${(contact as CompanyContact).companyName || ''}`;
  }
  const intl = useIntl();

  useEffect(() => {
    if (id) {
      setIsProcessing(true);
      getBucket(id)
        ?.then(setBucket)
        ?.finally(() => setIsProcessing(false));
    }
  }, [id]);

  useEffect(() => {
    if (bucket && bucket?.ecosystem) {
      setEcosystemId(bucket?.ecosystem);
      getDocumentsRelatedWithBucket(bucket).then(setRelatedDocuments);
    }
  }, [bucket]);

  useCustomEventListener('bucketSaved', () => {
    if (bucket && bucket?.ecosystem) {
      setEcosystemId(bucket?.ecosystem);
      getDocumentsRelatedWithBucket(bucket).then(setRelatedDocuments);
    }
  });

  useEffect(() => {
    const breadcrumbItems =
      activeKey === '1'
        ? [
            [
              `/app/${AppConfig.todixId}/operation_steps`,
              intl.formatMessage({
                id: 'operation.steps',
                defaultMessage: 'Operation steps',
              }),
            ],
            id
              ? [`/app/${AppConfig.todixId}/operation_steps/${id}`, contactName]
              : [
                  `/app/${AppConfig.todixId}/create`,
                  intl.formatMessage({
                    id: 'create.purchases',
                    defaultMessage: 'Create purchases',
                  }),
                ],
          ]
        : [
            [
              `/app/${AppConfig.todixId}`,
              intl.formatMessage({
                id: 'operation.steps',
                defaultMessage: 'Operation steps',
              }),
            ],
            [
              `/app/${DocumentAppConfig.todixId}/archive`,
              intl.formatMessage({
                id: 'app.documents.related',
                defaultMessage: 'Related documents',
              }),
            ],
          ];

    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [contactName, id, intl, activeKey]);

  return (
    <Spin spinning={isProcessing}>
      <Card>
        <TabsWithOverflowHidden
          defaultActiveKey="1"
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="tasks.detailedtask.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <BucketCreator
              bucket={bucket}
              bucketId={id}
              relatedDocuments={relatedDocuments}
              action={'update-purchases-requests'}
              ecosystemId={ecosystemId}
            />
          </TabPane>
          {id &&
            bucket &&
            getArchivedDocumentsTab({
              intl,
              fullEcosystem,
              relatedDocuments,
              blockedBreadcrumbs: true,
            })}
        </TabsWithOverflowHidden>
      </Card>
    </Spin>
  );
};

export default OperationStep;
