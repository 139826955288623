import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { PurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation';
import { PageLayout } from '@apps/purchases/services';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { getPurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation/purchaseOperationSettings';
import getLayout from '@apps/purchases/settings/layouts';
import { fetchUser } from '@services/users';
import { getRangeOfNumbersByEcosystem } from '@services/rangeOfNumbers';
import { getDocumentNumber } from '@apps/purchases/mainMenu/components/utils/utils';
import Footer from '@apps/purchases/mainMenu/components/Footer';
import {
  PageWrapper,
  TermsContainer,
} from '@apps/purchases/mainMenu/components/styles.sc';
import DeliveryTerms from '@apps/purchases/mainMenu/components/DeliveryTerms';
import PaymentTerms from '@apps/purchases/mainMenu/components/PaymentTerms';
import EcosystemAddress from '@apps/purchases/mainMenu/components/EcosystemAddress';
import LogoWithName from '@apps/purchases/mainMenu/components/LogoWithName';
import DocNumber from '@apps/purchases/mainMenu/components/DocNumber';
import OrderId from '@apps/purchases/mainMenu/components/OrderId';
import ContactDetails from '@apps/purchases/mainMenu/components/ContactDetails';
import Products from '@apps/sales/mainMenu/components/SaleCreator/components/Products';
import { Document } from '@apps/documents/service';
type Props = {
  values: any;
  readOnly: boolean;
  relatedDocuments?: Document[];
};

const PagePreview: FC<Props> = ({ values, readOnly }) => {
  const intl = useIntl();
  const { setFieldsValue, getFieldsValue } = useFormContext();
  const { ecosystem, type } = values;
  const [settings, setSettings] = useState<PurchaseOperationSettings | null>(
    null,
  );
  const [layout, setLayout] = useState<PageLayout>([]);
  const [rangeOfNumbers, setRangeOfNumbers] = useState<any>(null);
  const [docNumber, setDocNumber] = useState<string>('');
  const [user, setUser] = useState<User | null>(null);
  const ecosystemData = useSelector(
    getEcosystemById(ecosystem || ''),
  ) as Ecosystem;

  useEffect(() => {
    if (type && ecosystemData?.id) {
      getPurchaseOperationSettings(type, ecosystemData.id).then((results) => {
        if (results.length) {
          setSettings(results[0]);
        } else {
          setSettings(null);
        }
      });
    }
  }, [ecosystemData?.id, type]);

  useEffect(() => {
    if (type) {
      getLayout(type).then(setLayout);
    }
  }, [type]);

  useEffect(() => {
    if (ecosystemData) {
      fetchUser(ecosystemData.ownerUid).then((response) => {
        if (response) {
          setUser(response);
        }
      });
    }
  }, [ecosystemData]);

  useEffect(() => {
    if (ecosystem) {
      getRangeOfNumbersByEcosystem(ecosystem)?.then((el) => {
        setRangeOfNumbers(el);
      });
    }
  }, [ecosystem]);

  useEffect(() => {
    if (type) {
      const newNumber = `${getDocumentNumber(rangeOfNumbers, type)}`;
      setFieldsValue({
        ...getFieldsValue(),
        docNumber: newNumber,
      });
      setDocNumber(newNumber);
    }
  }, [type, rangeOfNumbers, setFieldsValue, getFieldsValue]);

  const footer = useMemo(() => {
    if (!layout || !user || !ecosystemData || !type) {
      return null;
    }

    return <Footer ecosystem={ecosystemData} user={user} settings={settings} />;
  }, [ecosystemData, layout, settings, user, type]);

  const terms = useMemo(() => {
    if (!layout) {
      return null;
    }
    const deliveryTerms = layout.find((el) => el.field === 'deliveryTerms');
    const paymentTerms = layout.find((el) => el.field === 'paymentTerms');
    return (
      <TermsContainer>
        {deliveryTerms && (
          <DeliveryTerms
            style={deliveryTerms.style}
            intl={intl}
            terms={values.deliveryTerms}
            forPdf={false}
          />
        )}
        {paymentTerms && (
          <PaymentTerms
            style={paymentTerms.style}
            intl={intl}
            terms={values.paymentTerms}
            forPdf={false}
          />
        )}
      </TermsContainer>
    );
  }, [intl, layout, values.deliveryTerms, values.paymentTerms]);

  return (
    <PageWrapper
      className="animate__animated animate__slideInLeft animate__faster"
      id="sales_form_preview"
    >
      {layout &&
        layout.length > 0 &&
        layout.map((el, index) => {
          const { avatarUrl, name } = ecosystemData;
          if (el.field === 'ecosystemAddress') {
            return (
              <EcosystemAddress
                key={index}
                address={ecosystemData.address}
                name={name}
                style={el.style}
                forPdf={false}
              />
            );
          }
          if (el.field === 'logoWithName') {
            return (
              <LogoWithName
                style={el.style}
                key={index}
                url={avatarUrl}
                forPdf={false}
              />
            );
          }
          if (el.field === 'docNumber' && type) {
            return (
              <DocNumber
                key={index}
                style={el.style}
                docNumber={docNumber}
                type={type}
                intl={intl}
                forPdf={false}
              />
            );
          }
          if (el.field === 'orderId' && type) {
            return (
              <OrderId
                key={index}
                style={el.style}
                intl={intl}
                forPdf={false}
                orderId={''}
                disabled={readOnly}
              />
            );
          }
          if (el.field === 'contactDetails') {
            return (
              <ContactDetails
                key={index}
                style={el.style}
                contact={values.relatedContactSnapshot}
                intl={intl}
                forPdf={false}
                disabled={readOnly}
              />
            );
          }
          if (el.field === 'products' && type) {
            return (
              <Products
                key={index}
                style={el.style}
                products={values.products}
                documentType={type}
                intl={intl}
                forPdf={false}
                ecosystem={ecosystem || ''}
                readOnly={readOnly}
              />
            );
          }
          return <div key={index} style={el?.style as CSSProperties}></div>;
        })}
      {terms}
      {footer}
    </PageWrapper>
  );
};

export default PagePreview;
