import get from 'lodash.get';
import set from 'lodash.set';
import { get as apiGet, getWithId } from '@services/api';
import { getFilteredData } from '@services/filtering';
import moment from 'moment';
import {
  GetApiResponse,
  IndividualPurchaseRequest,
} from '@apps/purchases/services';
import { patch, post } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import { INDIVIDUAL_REQUEST_COLLECTION_NAME } from '@apps/purchases/services/individualPurchaseRequests/namespace';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/purchases';
import intl from '../../../../../intl';

export * from './namespace';

export const getIndividualPurchaseRequest = (id: string) =>
  getWithId(
    `${INDIVIDUAL_REQUEST_COLLECTION_NAME}/${id}`,
  ) as Promise<IndividualPurchaseRequest>;

export const createIndividualPurchaseRequest = (
  request: IndividualPurchaseRequest,
) =>
  post(
    `${INDIVIDUAL_REQUEST_COLLECTION_NAME}`,
    {
      ...removeUndefinedProperties(request),
      timestamp: moment().valueOf(),
    },
    {
      message: true,
      messageContent: intl.formatMessage({
        id: 'request.created',
        defaultMessage: 'Request has been created',
      }),
    },
  );

export const updateIndividualPurchaseRequest = (
  id: string,
  request: IndividualPurchaseRequest,
) =>
  patch(
    `${INDIVIDUAL_REQUEST_COLLECTION_NAME}/${id}`,
    {
      ...removeUndefinedProperties(request),
      timestamp: moment().valueOf(),
    },
    {
      message: false,
    },
  );

export const getIndividualPurchaseRequests: GetApiResponse = (
  passedParams,
  action,
) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 50,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...passedParams,
  };
  // @ts-ignore
  return apiGet(INDIVIDUAL_REQUEST_COLLECTION_NAME)?.then(
    // @ts-ignore
    (data: IndividualPurchaseRequest[]) => {
      // filter, sort, limit (need info about all records)
      const filterEntries = Object.entries(params.filters);
      const shouldFilter = filterEntries.length;
      const shouldSort = !!params.sort.length;
      const filteredByEcosystems = data.filter((row) =>
        activeEcosystemsIds.includes(row.ecosystem as string),
      );
      const filteredByQuery = filteredByEcosystems.filter((row) =>
        params.fields.some((path) => {
          const fieldValue = get(row, path);
          if (typeof fieldValue !== 'string') {
            return false;
          }
          const queryLower = params.query.toLowerCase();
          return fieldValue.toLowerCase().includes(queryLower);
        }),
      );
      const filtered = shouldFilter
        ? getFilteredData(filteredByQuery, filterEntries)
        : filteredByQuery;
      const sorted = shouldSort
        ? [...filtered].sort((rowA, rowB) => {
            const sortKey = params.sort[0];
            const desc = sortKey.split('').includes('-');
            const path = sortKey
              .split('')
              .filter((c) => c !== '-')
              .join('');
            const valueA = get(rowA, path);
            const valueB = get(rowB, path);
            if (valueA === valueB) {
              return 0;
            }
            if (valueA < valueB) {
              return desc ? 1 : -1;
            } else {
              return desc ? -1 : 1;
            }
          })
        : filtered;
      const choosenFields = sorted.map((row) => {
        const newRow = {};
        params.fields.forEach((path: string): void => {
          const value = get(row, path);
          set(newRow, path, value);
        });
        return newRow;
      });
      const results = choosenFields.length;
      const page = choosenFields.slice(
        params.offset,
        params.offset + params.limit,
      );
      return {
        results: page,
        info: {
          results,
        },
      };
    },
  );
};
