import autoTable, { PageHook, RowInput } from 'jspdf-autotable';
import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';
import { BucketPosition } from '@apps/purchases/services/buckets';
import withSuffix from '@core/helpers/withSuffix';
import moment from 'moment';

type TitleEntry = {
  header: string;
  dataKey: string;
};

type DataEntry = {
  pos: string;
  productName: string;
  productNumber: string;
  needAmount: string;
  unit: string;
  unitAmount: string;
  description: string;
  expectedDeliveryDate: string;
};

const createData = (positions: BucketPosition[]): DataEntry[] => {
  return positions.map((entry, index) => {
    const pos = index + 1;
    return {
      pos: `${pos}`,
      productName: `${entry.productName || ''}`,
      productNumber: entry.productNumber || '',
      needAmount: `${entry.needAmount}`,
      unitAmount: withSuffix({
        value: entry.unitAmount,
      }),
      unit: `${entry?.unit || ' '}`,
      description: entry.description || '',
      expectedDeliveryDate:
        moment(entry.expectedDeliveryDate).format(moment.defaultFormat) || '',
    };
  });
};

const addPositions = (payload: ToolsProps, didDrawPage: PageHook) => {
  const { props, lastYPosition, doc } = payload;
  const {
    values: { positions },
    intl,
  } = props;
  const titles: TitleEntry[] = [
    {
      header: intl.formatMessage({
        id: 'sales.createForm.position.label',
        defaultMessage: 'Pos.',
      }),
      dataKey: 'pos',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createNew.item',
        defaultMessage: 'Product name',
      }),
      dataKey: 'productName',
    },
    {
      header: intl.formatMessage({
        id: 'products.form.productnumber.label',
        defaultMessage: 'Product number',
      }),
      dataKey: 'productNumber',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createForm.amount.label',
        defaultMessage: 'Amount',
      }),
      dataKey: 'needAmount',
    },
    {
      header: intl.formatMessage({
        id: 'products.selectedunit.label',
        defaultMessage: 'Unit',
      }),
      dataKey: 'unit',
    },
    {
      header: intl.formatMessage({
        id: 'price',
        defaultMessage: 'Price',
      }),
      dataKey: 'unitAmount',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createNew.description.label',
        defaultMessage: 'Description',
      }),
      dataKey: 'description',
    },
    {
      header: intl.formatMessage({
        id: 'expected.delivery.date',
        defaultMessage: 'Expected delivery date',
      }),
      dataKey: 'expectedDeliveryDate',
    },
  ];
  const data = createData(positions);

  autoTable(doc, {
    headStyles: {
      fillColor: '#8c8c8c',
      textColor: '#000',
    },
    footStyles: {
      fillColor: '#FFF',
      textColor: '#000',
    },
    styles: {
      font: 'time',
      fontSize: 8,
    },
    margin: { top: lastYPosition, left: 20 },
    columns: titles,
    body: data as RowInput[],
    didDrawPage,
  });
};

export default addPositions;
