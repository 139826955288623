import React, { FC } from 'react';
import { Form } from 'antd';
import BucketStatusRenderer from '@apps/purchases/widgets/BucketStatusRenderer';

const { Item } = Form;

const BucketStatus: FC = () => {
  return (
    <Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const status = getFieldValue('status');
        return (
          <Item name="status">
            <BucketStatusRenderer value={status} />
          </Item>
        );
      }}
    </Item>
  );
};

export default BucketStatus;
