import React, { FC } from 'react';
import intl from '../../../../../../../../intl';
import { Form } from 'antd';
import { NumericFormat } from 'react-number-format';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { ShortInput } from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
const { Item } = Form;

const TaskInput: FC = () => {
  const { readOnly, setFieldsValue, getFieldsValue } = useFormContext();
  const formValues = getFieldsValue();
  return (
    <Item
      name="tax"
      label={intl.formatMessage({
        id: 'sales.createForm.tax.label',
        defaultMessage: 'Tax (%)',
      })}
      required
    >
      <NumericFormat
        disabled={readOnly}
        allowNegative={false}
        max={'100'}
        decimalSeparator={','}
        decimalScale={2}
        thousandSeparator={'.'}
        customInput={ShortInput}
        onChange={(event) => {
          const value = event.target.value;
          const formatedValue = value.replaceAll('.', '').replaceAll(',', '.');
          const taxValue = formatedValue !== '' ? parseFloat(formatedValue) : 0;
          let filteredTaxValue = taxValue;
          if (taxValue < 0) {
            filteredTaxValue = 0;
          }
          if (taxValue > 100) {
            filteredTaxValue = 100;
          }
          setFieldsValue({
            ...formValues,
            tax: filteredTaxValue,
          });
        }}
      />
    </Item>
  );
};

export default TaskInput;
