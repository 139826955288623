import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';

const addFooter = (payload: ToolsProps) => {
  const { props, doc, lastYPosition } = payload;
  const { values } = props;
  const { footer } = values;
  let newLastYPosition = lastYPosition;
  if (footer) {
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(8);
    let xPosition = 20;
    if (footer.content) {
      if (Array.isArray(footer.content)) {
        footer.content.forEach((part) => {
          part.split('\n').forEach((element) => {
            doc.text(element, xPosition, newLastYPosition, { maxWidth: 40 });
            newLastYPosition = newLastYPosition + 3;
          });
          newLastYPosition = lastYPosition;
          xPosition = xPosition + 50;
        });
      } else {
        const content = footer.content as Record<any, string>;
        Object.values(content).forEach((part) => {
          part.split('\n').forEach((element) => {
            doc.text(element, xPosition, newLastYPosition, { maxWidth: 40 });
            newLastYPosition = newLastYPosition + 3;
          });
          newLastYPosition = lastYPosition;
          xPosition = xPosition + 50;
        });
      }
    }
  }
};

export default addFooter;
