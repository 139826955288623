import { jsPDF } from 'jspdf';
import { PurchasePDFTemplateProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';
import addAvatar from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addAvatar';
import addEcosystemData from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addEcosystemData';
import addSupplier from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addSupplier';
import addDocumentData from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addDocumentData';
import addDocNumber from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addDocNumber';
import addPositions from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addPositions';
import addTextField from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addTextField';
import addTerms from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addTerms';
import intl from '../../../../../../intl';
import addFooter from '@apps/purchases/mainMenu/components/pdfTemplates/tools/addFooter';

const createTemplate = async (
  props: PurchasePDFTemplateProps,
): Promise<jsPDF> => {
  const doc = new jsPDF();
  doc.setFont('times', 'normal', 'normal');
  let lastYPosition = 20;
  await addAvatar({ doc, props, lastYPosition });
  lastYPosition = 50;
  lastYPosition = addEcosystemData({ doc, props, lastYPosition });
  lastYPosition = lastYPosition + 10;
  addSupplier({ doc, props, lastYPosition });
  lastYPosition = addDocumentData({ doc, props, lastYPosition });
  lastYPosition = lastYPosition + 25;
  addDocNumber({ doc, props, lastYPosition });
  lastYPosition = lastYPosition + 15;
  if (props.values.freeTextStart) {
    addTextField(props.values.freeTextStart, { doc, props, lastYPosition });
    lastYPosition = lastYPosition + 10;
  }
  addPositions({ doc, props, lastYPosition }, (hookData) => {
    const { pageCount, pageNumber, cursor } = hookData;
    if (pageCount === pageNumber) {
      lastYPosition = (cursor?.y as number) + 10;
      if (props.values.freeTextEnd) {
        addTextField(props.values.freeTextEnd, { doc, props, lastYPosition });
        lastYPosition = lastYPosition + 15;
      }
      if (props.values.deliveryAddress) {
        doc.setFont('times', 'normal', 700);
        doc.text(
          intl.formatMessage({
            id: 'delivery.address',
            defaultMessage: 'Delivery address',
          }),
          20,
          lastYPosition,
        );
        lastYPosition = lastYPosition + 15;
        addTextField(props.values.deliveryAddress, {
          doc,
          props,
          lastYPosition,
        });
        lastYPosition = lastYPosition + 15;
      }

      addTerms({ doc, lastYPosition, props });
    }
    addFooter({ doc, lastYPosition: 275, props });
  });
  doc.close();
  return doc;
};

export default createTemplate;
