import React, { FC, CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
  forPdf: boolean;
  address?: CommonEcosystemData['address'];
  name?: CommonEcosystemData['name'];
};

const EcosystemAddress: FC<Props> = ({ style, address, name }) => {
  return (
    <div style={style as CSSProperties}>
      {name && <span>{name}</span>}
      {address && (
        <div>
          {`${address.street}, ${address.number}, ${address.city}, ${
            address.zipcode
          }, ${address.country || ''}`}
        </div>
      )}
    </div>
  );
};

export default EcosystemAddress;
