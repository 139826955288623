import React, { FC, useState, useEffect } from 'react';
import { Spin, Empty } from 'antd';
import MediaViewer from '@components/MediaViewer';
import { Document } from '@apps/documents/service/namespace';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';
import { getDocumentsByIds } from '@apps/documents/service';
import NoImagePlaceholder from '@components/NoImagePlaceholder';
import { useIntl } from 'react-intl';

type Props = {
  ecosystemId: string;
  productId: string;
};

const ProductImagesInCard: FC<Props> = ({ ecosystemId, productId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState<Document[]>([]);
  const intl = useIntl();

  useEffect(() => {
    if (ecosystemId && productId) {
      setIsLoading(true);
      getChildrenForParent({
        ecosystem: ecosystemId,
        parentId: productId,
        childType: 'document',
      })
        .then((children) => {
          const childrenIds = children.map((child) => child.childId);
          getDocumentsByIds(childrenIds)
            .then((documents) => {
              if (documents.length) {
                setDocs([documents[0]]);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [ecosystemId, productId]);
  return (
    <div style={{ padding: '5px' }}>
      <Spin spinning={isLoading}>
        {!docs.length && !isLoading && (
          <Empty
            description={
              <span>
                {intl.formatMessage({
                  id: 'no.image',
                  description: 'No image',
                })}
              </span>
            }
            image={<NoImagePlaceholder />}
          />
        )}
        {docs.length > 0 && !isLoading && (
          <MediaViewer
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            items={docs.map((doc) => ({
              url: doc.documentUrl,
              thumbnailUrl:
                doc.fileDetails.format === 'pdf'
                  ? doc.thumbnailUrl || 'placeholder'
                  : doc.documentUrl,
              format: doc.fileDetails.format as string,
            }))}
          />
        )}
      </Spin>
    </div>
  );
};

export default ProductImagesInCard;
