import React, { FC } from 'react';
import { Form, InputNumber } from 'antd';
import intl from '../../../../../../intl';

const { Item } = Form;

const StandardDeliveryTime: FC = () => {
  return (
    <Item
      name="standardDeliveryTime"
      label={intl.formatMessage({
        id: 'standard.delivery.time.days',
        defaultMessage: 'Standard delivery time (days)',
      })}
      required
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'standard.delivery.time.missing',
            defaultMessage: 'Standard delivery time is missing',
          }),
        },
      ]}
    >
      <InputNumber
        placeholder={intl.formatMessage({
          id: 'standard.delivery.time.days',
          defaultMessage: 'Standard delivery time (days)',
        })}
        autoComplete="nope"
        step={1}
        precision={0}
        min={0}
      />
    </Item>
  );
};

export default StandardDeliveryTime;
