import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';

export type PURCHASES_ACTION =
  | 'view-purchases'
  | 'create-purchases'
  | 'update-purchases'
  | 'delete-purchases'
  | 'view-purchases-requests'
  | 'create-purchases-requests'
  | 'update-purchases-requests'
  | 'delete-purchases-requests';

export type PURCHASES_PERMISSION = 'all' | 'none';

export default {
  todixId: 'purchases',
  link: '/app/purchases',
  name: 'purchases',
  entityType: 'purchase',
  collectionName: 'purchases',
  localizationPath: 'purchases/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.purchases.createNewLink.new.purchases',
    },
    {
      link: 'purchase_request',
      title: 'app.purchases.createNewLink.new.purchase.request',
    },
  ],
  widgets: [
    {
      name: 'AppRolesForm',
      path: 'purchases/widgets/AppRolesForm',
    },
  ],
  listCellRenderers: [
    {
      name: 'bucketStatusRenderer',
      path: 'purchases/widgets/BucketStatusRenderer',
    },
  ],
  actions: [
    'view-purchases',
    'create-purchases',
    'update-purchases',
    'delete-purchases',
    'view-purchases-requests',
    'create-purchases-requests',
    'update-purchases-requests',
    'delete-purchases-requests',
  ] as PURCHASES_ACTION[],
  permissions: ['all', 'none'] as PURCHASES_PERMISSION[],
} as FileApp;
