import React, { CSSProperties, FC } from 'react';
import { IntlShape } from 'react-intl';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import ProductsTable from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsTable';
import ProductsSummary from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsSummary';
import { DocumentType } from '@apps/documents/service';

type Props = {
  style?: CSSProperties;
  intl: IntlShape;
  forPdf: boolean;
  products: SalesFormValues['products'];
  ecosystem: string;
  documentType: DocumentType;
  readOnly?: boolean;
};

const Products: FC<Props> = ({
  style,
  products,
  intl,
  ecosystem,
  documentType,
  readOnly = false,
}) => {
  return (
    <div style={{ ...(style as CSSProperties) }}>
      <ProductsTable
        readOnly={readOnly}
        products={products}
        intl={intl}
        ecosystem={ecosystem}
        documentType={documentType}
      />
      <ProductsSummary
        products={products}
        intl={intl}
        documentType={documentType}
      />
    </div>
  );
};

export default Products;
