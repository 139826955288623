import React, { FC, useEffect, useState } from 'react';
import { Document } from '@apps/documents/service';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { fetchUser } from '@services/users';
import { PageWrapper } from '@apps/purchases/mainMenu/components/styles.sc';
import { Bucket } from '@apps/purchases/services/buckets';
import { PageLayout } from '@apps/purchases/services';
import getLayout from '@apps/purchases/settings/layouts';
import EcosystemAddress from '@apps/purchases/mainMenu/components/EcosystemAddress';
import LogoWithName from '@apps/purchases/mainMenu/components/LogoWithName';
import ContactDetails from '@apps/purchases/mainMenu/components/ContactDetails';
import intl from '../../../../../../intl';
import Positions from '@apps/purchases/mainMenu/operationSteps/operationStep/positions';
import { PurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation';
import { getPurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation/purchaseOperationSettings';
import { getDocumentNumber } from '@apps/purchases/mainMenu/components/utils/utils';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import Footer from '@apps/purchases/mainMenu/components/Footer';
import DeliveryTerms from '@apps/purchases/mainMenu/components/DeliveryTerms';
import PaymentTerms from '@apps/purchases/mainMenu/components/PaymentTerms';
import DocNumber from '@apps/purchases/mainMenu/components/DocNumber';
import getPageLayout from '@apps/purchases/settings/layouts/page.layouts.sc';
import FreeTextField from '@apps/purchases/settings/operationSteps/FreeTextField';
import StyledInputItem from '@apps/purchases/mainMenu/operationSteps/operationStep/StyledInputItem';
import StyledDatePickerItem from '@apps/purchases/mainMenu/operationSteps/operationStep/StyledDatePickerItem';
import moment from 'moment/moment';
import { RangeOfNumbersType } from '@services/rangeOfNumbers';
import stepTypeToDocType from '@apps/purchases/mainMenu/operationSteps/operationStep/stepTypeToDocType';

type Props = {
  values: Bucket;
  readOnly: boolean;
  relatedDocuments?: Document[];
  rangeOfNumbers: RangeOfNumbersType | null;
};

const PagePreview: FC<Props> = ({ values, readOnly, rangeOfNumbers }) => {
  const { setFieldsValue, getFieldsValue, getFieldValue } = useFormContext();
  const { ecosystem, stepType, status } = values;
  const docNumber = getFieldValue('docNumber') || '';
  const [settings, setSettings] = useState<PurchaseOperationSettings | null>(
    null,
  );
  const [user, setUser] = useState<User | null>(null);
  const [layout, setLayout] = useState<PageLayout>([]);
  const ecosystemData = useSelector(
    getEcosystemById(ecosystem || ''),
  ) as Ecosystem;

  useEffect(() => {
    if (ecosystemData) {
      fetchUser(ecosystemData.ownerUid).then((response) => {
        if (response) {
          setUser(response);
        }
      });
    }
  }, [ecosystemData]);

  useEffect(() => {
    if (stepType) {
      const type = stepTypeToDocType[stepType];
      if (type) {
        getLayout(type).then(setLayout);
      }
    }
  }, [stepType]);

  useEffect(() => {
    if (stepType && ecosystemData?.id) {
      const type = stepTypeToDocType[stepType];
      getPurchaseOperationSettings(type, ecosystemData.id).then((results) => {
        if (results.length) {
          const docSettings = results[0];
          const freeTextStart = getFieldValue('freeTextStart') || '';
          const freeTextEnd = getFieldValue('freeTextEnd') || '';
          const footer = getFieldValue('footer') || {
            content: ['', '', '', ''],
          };
          const draftSaved = getFieldValue('draftSaved');

          if (!draftSaved) {
            setFieldsValue({
              ...getFieldsValue(),
              freeTextStart: !freeTextStart
                ? docSettings.freeTextStart || ''
                : freeTextStart,
              freeTextEnd: !freeTextEnd
                ? docSettings.freeTextEnd || ''
                : freeTextEnd,
              footer: docSettings.footer || footer,
            });
          }

          setSettings(docSettings);
        } else {
          setSettings(null);
        }
      });
    }
  }, [
    ecosystemData?.id,
    getFieldValue,
    getFieldsValue,
    setFieldsValue,
    stepType,
  ]);

  useEffect(() => {
    if (stepType && status === 'draft') {
      const type = stepTypeToDocType[stepType];
      const newNumber = `${getDocumentNumber(rangeOfNumbers, type)}`;
      setFieldsValue({
        ...getFieldsValue(),
        docNumber: newNumber,
      });
    }
  }, [stepType, rangeOfNumbers, setFieldsValue, getFieldsValue, status]);

  const PageLayout = getPageLayout(stepTypeToDocType[stepType]);

  return (
    <PageWrapper
      className="animate__animated animate__slideInLeft animate__faster"
      id="sales_form_preview"
    >
      <PageLayout>
        {layout &&
          layout.length > 0 &&
          layout.map((el, index) => {
            const { avatarUrl, name } = ecosystemData;
            if (el.field === 'ecosystemAddress') {
              return (
                <EcosystemAddress
                  key={index}
                  address={ecosystemData.address}
                  name={name}
                  style={el.style}
                  forPdf={false}
                />
              );
            }
            if (el.field === 'logoWithName') {
              return (
                <LogoWithName
                  style={el.style}
                  key={index}
                  url={avatarUrl}
                  forPdf={false}
                />
              );
            }
            if (el.field === 'documentNumber' && stepType) {
              return (
                <DocNumber
                  key={index}
                  style={el.style}
                  docNumber={docNumber}
                  type={stepTypeToDocType[stepType]}
                  intl={intl}
                  forPdf={false}
                />
              );
            }
            if (el.field === 'supplier') {
              return (
                <ContactDetails
                  key={index}
                  style={el.style}
                  contact={values.relatedContactSnapshot}
                  intl={intl}
                  forPdf={false}
                  disabled={true}
                />
              );
            }
            if (el.field === 'positions' && stepType) {
              return (
                <Positions
                  key={index}
                  style={el.style as any}
                  intl={intl}
                  bucket={values}
                  ecosystem={values.ecosystem || ''}
                  readOnly={readOnly}
                />
              );
            }
            if (el.field === 'deliveryTerms') {
              return (
                <DeliveryTerms
                  style={el.style}
                  intl={intl}
                  terms={values.deliveryTerms}
                  forPdf={false}
                />
              );
            }
            if (el.field === 'paymentTerms') {
              return (
                <PaymentTerms
                  style={el.style}
                  intl={intl}
                  terms={values.paymentTerms}
                  forPdf={false}
                />
              );
            }
            if (el.field === 'freeTextStart') {
              return (
                <FreeTextField
                  itemStyle={el.style}
                  fieldName="freeTextStart"
                  placeholder={intl.formatMessage({
                    id: 'free.text.start',
                    defaultMessage: 'Free text start',
                  })}
                  size="large"
                  rows={2}
                  showCount
                  maxLength={115}
                />
              );
            }
            if (el.field === 'freeTextEnd') {
              return (
                <FreeTextField
                  itemStyle={el.style}
                  fieldName="freeTextEnd"
                  placeholder={intl.formatMessage({
                    id: 'free.text.end',
                    defaultMessage: 'Free text end',
                  })}
                  size="large"
                  rows={2}
                  showCount
                  maxLength={115}
                />
              );
            }
            if (el.field === 'deliveryAddress') {
              return (
                <FreeTextField
                  itemStyle={el.style}
                  fieldName="deliveryAddress"
                  placeholder={intl.formatMessage({
                    id: 'delivery.address',
                    defaultMessage: 'Delivery address',
                  })}
                  size="large"
                  rows={4}
                  showCount
                  maxLength={115}
                />
              );
            }
            if (el.field === 'referenceNumber') {
              return (
                <StyledInputItem
                  style={el.style}
                  name="referenceNumber"
                  label={intl.formatMessage({
                    id: 'reference.number',
                    defaultMessage: 'Reference number',
                  })}
                />
              );
            }
            if (el.field == 'creationDate') {
              return (
                <StyledDatePickerItem
                  style={el.style}
                  name="creationDate"
                  label={intl.formatMessage({
                    id: 'sales.columns.creationDate.header',
                    defaultMessage: 'Creation Date',
                  })}
                  datePickerProps={{
                    disabled: true,
                    format: moment.defaultFormat,
                    bordered: false,
                    suffixIcon: null,
                  }}
                />
              );
            }
            if (el.field === 'footer') {
              return (
                <Footer
                  style={el.style}
                  ecosystem={ecosystemData}
                  user={user}
                  settings={settings}
                />
              );
            }
            return null;
          })}
      </PageLayout>
    </PageWrapper>
  );
};

export default PagePreview;
