import React, { FC } from 'react';
import { ProductWithPrice } from '@apps/sales/services';
import { ProductPrice } from '@apps/products/services';
import { useIntl } from 'react-intl';
import { NumericFormatProps } from 'react-number-format';
import { Form } from 'antd';
import { ItemNoMargin, StyledMoneyInput } from './styles.sc';

const { Item } = Form;

type Props = NumericFormatProps & {
  selectedEcosystem?: string | null;
  product: ProductWithPrice;
  prices: ProductPrice[];
  handleSave(): void;
  fieldName?: string;
};

const calculateValues = (
  fieldName: string,
  priceValue: number,
  formValues: Record<string, any>,
) => {
  const { discount, taxValue, amount } = formValues;
  const price = priceValue !== undefined ? priceValue : 0;
  const discountedPrice = Number(
    Number(price - (Number(discount) / 100) * price).toFixed(2),
  );
  const grossPrice = Number(
    Number(discountedPrice + discountedPrice * (taxValue / 100)).toFixed(2),
  );
  const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
  const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

  return {
    [fieldName]: priceValue,
    discountedPrice,
    grossPrice,
    totalNetPrice,
    totalGrossPrice,
  };
};

const PriceInOrder: FC<Props> = ({
  prices,
  product,
  handleSave,
  fieldName = 'price',
  ...rest
}) => {
  const intl = useIntl();
  const defaultPrice = prices.find((price) => price.isDefaultPrice);
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={fieldName}>
            <StyledMoneyInput
              currency={defaultPrice?.currency || 'EUR'}
              placeholder={intl.formatMessage({
                id: 'sales.createForm.price.label',
                defaultMessage: 'Price',
              })}
              onChange={(event) => {
                const priceString = event.target.value;
                if (priceString !== '') {
                  const floatValue = Number(
                    priceString.replaceAll('.', '').replaceAll(',', '.'),
                  );
                  const newValues = calculateValues(
                    fieldName,
                    floatValue,
                    formValues,
                  );

                  setFieldsValue({
                    ...formValues,
                    ...newValues,
                  });

                  if (handleSave) {
                    handleSave();
                  }
                }
              }}
              onValueChange={({ floatValue = 0 }) => {
                const newValues = calculateValues(
                  fieldName,
                  floatValue,
                  formValues,
                );

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
              {...rest}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default PriceInOrder;
