import React from 'react';
import { Input } from 'antd';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { currencySigns } from '@services/currency';

type Props = NumericFormatProps & {
  currency?: string;
};

type GetMoneyValueResult = {
  result: string;
  str: string;
  dec: string;
};

export const getMoneyValue = (payload: {
  prefix: string;
  commaSeparator?: string;
  value: string;
}): GetMoneyValueResult | null => {
  const { prefix, commaSeparator, value } = payload;
  let val = Number(value).toFixed(2);
  const pad = '000';
  const reg = new RegExp(/^\d+$/);
  const ds = commaSeparator ? '.' : ','; // decimal seperator
  const ts = commaSeparator ? ',' : '.'; // decimal seperator
  const p = prefix || '';

  val = val.replaceAll(ts, '');
  val = val.replaceAll(ds, '');
  val = p ? val.replaceAll(p, '') : val;

  if (reg.test(val)) {
    let str = pad.substring(0, pad.length - val.length) + val;

    if (str.length > 3) {
      for (let i = 0; i < str.length - 3; i++) {
        if (str[i] === '0') {
          str = str.substring(1);
        }
      }
    }

    const dec = str.substring(str.length - 2);
    str = str.substring(0, str.length - 2);

    let fStr = ''; // formatted str
    let t = 0;

    for (let i = 0; i < str.length; i++) {
      const last = str[str.length - i - 1];
      if (t > 1) {
        fStr = ts + last + fStr;
        t = 0;
      } else {
        fStr = last + fStr;
        t++;
      }
    }

    fStr =
      (prefix || '') + (fStr[0] === ts ? fStr.substring(1) : fStr) + ds + dec;

    return {
      result: fStr,
      str,
      dec,
    };
  }
  return null;
};

const SuffixedInput = (props: any) => {
  return <Input {...props} suffix={currencySigns['EUR']} />;
};

const InputWithoutSuffix = (props: any) => {
  return <Input {...props} />;
};

export const NumberInput: React.FC<Props> = (props) => {
  const { currency, onChange, onBlur, ...rest } = props;

  return (
    <NumericFormat
      {...rest}
      decimalSeparator={','}
      decimalScale={2}
      onBlur={onBlur}
      onChange={onChange}
      customInput={InputWithoutSuffix}
      thousandSeparator={'.'}
    />
  );
};

const MoneyInput: React.FC<Props> = (props) => {
  const { currency, onChange, onBlur, ...rest } = props;

  return (
    <NumericFormat
      {...rest}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale
      onBlur={onBlur}
      onChange={onChange}
      customInput={SuffixedInput}
      thousandSeparator={'.'}
    />
  );
};

export default MoneyInput;
