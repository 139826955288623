import React, { FC } from 'react';
import { Form } from 'antd';
import { Toggle } from '@todix/ui-components';
import intl from '../../../../../../intl';

const { Item } = Form;

type Props = {
  disabled?: boolean;
};

const IsDefault: FC<Props> = ({ disabled }) => {
  return (
    <Item
      name="isDefault"
      label={intl.formatMessage({
        id: 'default.supplier',
        defaultMessage: 'Default supplier',
      })}
      valuePropName="checked"
    >
      <Toggle disabled={disabled} />
    </Item>
  );
};

export default IsDefault;
