import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';
import stepTypeToDocType from '@apps/purchases/mainMenu/operationSteps/operationStep/stepTypeToDocType';
import { BucketStep } from '@apps/purchases/services/buckets';

const addDocNumber = (payload: ToolsProps) => {
  const { props, doc, lastYPosition } = payload;
  const { intl, values } = props;
  const { docNumber, stepType } = values;
  const type = stepTypeToDocType[stepType as BucketStep];
  if (docNumber && type) {
    const documentNumber = `${intl.formatMessage({
      id: `${type}`,
      defaultMessage: type,
    })}: ${docNumber}`;
    doc.setFont('times', 'normal', 700);
    doc.setFontSize(13);
    doc.text(documentNumber, 20, lastYPosition);
  }
};

export default addDocNumber;
