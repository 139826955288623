import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { NumericFormatProps } from 'react-number-format';
import { Form } from 'antd';
import { ItemNoMargin, StyledMoneyInput } from './styles.sc';
import { EditableBucketPosition } from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/EditableCell';

const { Item } = Form;

type Props = NumericFormatProps & {
  selectedEcosystem?: string | null;
  product: EditableBucketPosition;
  handleSave(): void;
  fieldName?: string;
};

const calculateValues = (
  fieldName: string,
  priceValue: number,
  formValues: Record<string, any>,
) => {
  const { amount } = formValues;
  const price = priceValue !== undefined ? priceValue : 0;
  const totalGrossPrice = Number(Number(price * amount).toFixed(2));

  return {
    [fieldName]: priceValue,
    totalGrossPrice,
  };
};

const PriceInOrder: FC<Props> = ({
  handleSave,
  fieldName = 'unitAmount',
  ...rest
}) => {
  const intl = useIntl();
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={fieldName}>
            <StyledMoneyInput
              currency={'EUR'}
              placeholder={intl.formatMessage({
                id: 'sales.createForm.price.label',
                defaultMessage: 'Price',
              })}
              onChange={(event) => {
                const priceString = event.target.value;
                if (priceString !== '') {
                  const floatValue = Number(
                    priceString.replaceAll('.', '').replaceAll(',', '.'),
                  );
                  const newValues = calculateValues(
                    fieldName,
                    floatValue,
                    formValues,
                  );

                  setFieldsValue({
                    ...formValues,
                    ...newValues,
                  });

                  if (handleSave) {
                    handleSave();
                  }
                }
              }}
              onValueChange={({ floatValue = 0 }) => {
                const newValues = calculateValues(
                  fieldName,
                  floatValue,
                  formValues,
                );

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
              {...rest}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default PriceInOrder;
