import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { emitCustomEvent } from '@core/services';
import StyledItemForm from '@apps/documents/mainMenu/components/StyledItemForm';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { BucketStep } from '@apps/purchases/services/buckets';

type PurchaseStepProps = {
  fieldName?: string;
  allowingTypes: BucketStep[];
  onStepTypeSelect?(stepType: any): void;
};

const PurchaseStep: FC<PurchaseStepProps> = ({
  allowingTypes,
  fieldName = 'type',
  onStepTypeSelect,
}) => {
  const intl = useIntl();
  const { readOnly } = useFormContext();

  const onSelect = useCallback(
    (type) => {
      if (onStepTypeSelect) {
        onStepTypeSelect(type);
      }
      emitCustomEvent('setPurchaseStep', {
        type,
      });
    },
    [onStepTypeSelect],
  );

  return (
    <StyledItemForm
      name={fieldName}
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'sales.select.operation.step.error',
            defaultMessage: 'Missing operation step',
          }),
        },
      ]}
    >
      <Select
        showSearch
        disabled={readOnly}
        optionFilterProp="label"
        placeholder={intl.formatMessage({
          id: `sales.select.operation.step`,
          defaultMessage: 'Select operation step...',
        })}
        onSelect={onSelect}
        filterOption={(input, option) => {
          return option
            ? option['data-forsearch']
                .toLowerCase()
                .includes(input.toLowerCase())
            : false;
        }}
        options={allowingTypes.map((type) => {
          return {
            label: (
              <div>
                {intl.formatMessage({
                  id: `${type}`,
                  defaultMessage: type,
                })}
              </div>
            ),
            'data-forsearch': `${intl.formatMessage({
              id: `${type}`,
              defaultMessage: type,
            })}`,
            value: type,
          };
        })}
      />
    </StyledItemForm>
  );
};

export default PurchaseStep;
