import React, { FC } from 'react';
import { Product } from '@apps/products/services';
import { ActionRendererProps } from '@components/AppView/ActionRenderer';
import ProductCard from '@apps/products/mainMenu/components/ProductCard/ProductCard';
import { useIntl } from 'react-intl';
import { ProductStockEntries } from '@apps/products/widgets/styles.sc';

type Props = {
  item: Product;
  onCardClick(): void;
  actionRendererProps: ActionRendererProps;
};

const ProductCardInStock: FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <ProductCard {...props}>
      <ProductStockEntries>
        <div>
          <div>
            {intl.formatMessage({
              id: 'in.stock',
              description: 'In stock',
            })}
            :
          </div>
          <div></div>
        </div>
        <div>
          <div>
            {intl.formatMessage({
              id: 'available',
              description: 'Available',
            })}
            :
          </div>
          <div></div>
        </div>
      </ProductStockEntries>
    </ProductCard>
  );
};

export default ProductCardInStock;
