import React, { FC, useCallback, useMemo } from 'react';
import { ProductContactConstraint } from '@apps/products/services';
import intl from '../../../../../intl';
import { Form, Select, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@components/FormWithReminder/useFormContext';

const { Item } = Form;

type SupplierProps = {
  onContactSet?(): void;
  disabled?: boolean;
  constraints: ProductContactConstraint[];
};

const Supplier: FC<SupplierProps> = ({
  disabled,
  constraints,
  onContactSet,
}) => {
  const { setFieldsValue, getFieldsValue } = useFormContext();
  const handleChange = useCallback(
    (id) => {
      if (disabled || constraints === null) {
        return;
      }
      const contact = constraints
        .map((el) => el.relatedContactSnapshot)
        .find((contact) => contact.id === id);
      if (contact) {
        setFieldsValue({
          ...getFieldsValue(),
          relatedContactSnapshot: {
            ...contact,
            addresses: contact.addresses || [],
            emails: contact.emails || [],
            phones: contact.phones || [],
          },
        });
        if (onContactSet) {
          onContactSet();
        }
      }
    },
    [constraints, disabled, getFieldsValue, onContactSet, setFieldsValue],
  );

  const options = useMemo(() => {
    if (constraints === null) {
      return [];
    }
    return constraints
      .map((el) => el.relatedContactSnapshot)
      .map((contact, index) => {
        const address = contact?.addresses
          ? contact.addresses.find((add) => add.standard)
          : undefined;
        return {
          value: contact.id,
          'data-forsearch': `${contact?.companyName || ''} ${
            contact?.firstName || ''
          } ${contact?.lastName || ''}`,
          label: (
            <Space size="small" direction="vertical" key={index}>
              {contact.type === 'company' && <div>{contact.companyName}</div>}
              {contact.type === 'person' && (
                <div>
                  {contact.firstName} {contact.lastName}
                </div>
              )}
              {address && (
                <div>
                  {`${address.street || ''} ${address.number || ''} ${
                    address.zipcode || ''
                  } ${address.city || ''} ${address.country || ''}`}
                </div>
              )}
            </Space>
          ),
        };
      });
  }, [constraints]);
  return (
    <Item
      name="relatedContact"
      required
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'missing.supplier',
            defaultMessage: 'Supplier is missing',
          }),
        },
      ]}
    >
      <Select
        disabled={disabled}
        style={{ minWidth: '300px' }}
        showSearch={true}
        onChange={handleChange}
        filterOption={(inputValue, option) => {
          return option
            ? option['data-forsearch']
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            : false;
        }}
        placeholder={
          <FormattedMessage
            id="add.supplier"
            defaultMessage="Add supplier..."
          />
        }
        options={options}
      />
    </Item>
  );
};

export default Supplier;
