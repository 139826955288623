import React, { CSSProperties, FC } from 'react';

type LogoWithNameProps = {
  name?: string;
  url?: string;
  style?: CSSProperties;
  forPdf: boolean;
};

const LogoWithName: FC<LogoWithNameProps> = ({ style, name, url }) => {
  return (
    <div style={style as CSSProperties}>
      <div>{name || ''}</div>
      {url && (
        <img alt="logo" src={url} style={{ width: '20mm', height: 'auto' }} />
      )}
    </div>
  );
};

export default LogoWithName;
