import React, { FC, useRef } from 'react';
import { DatePicker, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import TextArea from 'antd/lib/input/TextArea';
import { EditableBucketPosition } from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/EditableCell';
import moment from 'moment/moment';
import { ExpandedRowContainer } from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/styles.sc';

const { Item } = Form;

type Props = {
  namePrefix: number;
  record: EditableBucketPosition;
  onBlur(newRecord: EditableBucketPosition): void;
};

const ExpandedRow: FC<Props> = ({ record, namePrefix, onBlur }) => {
  const intl = useIntl();
  const descriptionRef = useRef('');
  return (
    <ExpandedRowContainer>
      <Item
        initialValue={record.description}
        name={
          namePrefix !== undefined
            ? ['positions', namePrefix, `description`]
            : `description`
        }
        rules={[
          {
            type: 'string',
            max: 5000,
            message: (
              <FormattedMessage
                id="products.form.description.error"
                defaultMessage="Description cannot be longer than 5000 characters"
              />
            ),
          },
        ]}
      >
        <TextArea
          placeholder={intl.formatMessage({
            id: 'products.form.description.placeholder',
            defaultMessage: 'Maximum 500 characters...',
          })}
          onChange={(event) => {
            descriptionRef.current = event.target.value;
          }}
          onBlur={() => {
            onBlur({
              ...record,
              description: descriptionRef.current,
            });
          }}
        />
      </Item>
      <Item
        name={
          namePrefix !== undefined
            ? ['positions', namePrefix, `expectedDeliveryDate`]
            : `expectedDeliveryDate`
        }
        initialValue={moment(record.expectedDeliveryDate)}
        label={intl.formatMessage({
          id: 'expected.delivery.date',
          defaultMessage: 'Expected delivery date',
        })}
      >
        <DatePicker
          format={moment.defaultFormat}
          placeholder={intl.formatMessage({
            id: 'select.date',
            defaultMessage: 'Select date',
          })}
          disabled
        />
      </Item>
    </ExpandedRowContainer>
  );
};

export default ExpandedRow;
