import React, { CSSProperties, FC } from 'react';
import { DatePicker, Form } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment/moment';
import { Style } from '@react-pdf/types';
import { IntlShape } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

const { Item } = Form;

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  validTill?: string;
  disabled?: boolean;
};

const ValidTill: FC<Props> = ({ forPdf, validTill, intl, disabled, style }) => {
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current <= moment().endOf('day');
  };

  if (forPdf && validTill) {
    return (
      <View style={style}>
        <Text>{validTill}</Text>
      </View>
    );
  }

  return (
    <div style={style as CSSProperties}>
      <Item
        name="validTill"
        label={intl.formatMessage({
          id: 'valid.until',
          defaultMessage: 'Valid until',
        })}
        style={{ gridTemplateColumns: '1fr 1fr' }}
      >
        <DatePicker disabled={disabled} disabledDate={disabledDate} />
      </Item>
    </div>
  );
};

export default ValidTill;
