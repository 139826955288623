import React, { FC } from 'react';
import { Form, Input, InputProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

const { Item } = Form;

type Props = FormItemProps & {
  inputProps?: InputProps;
};

const StyledInputItem: FC<Props> = ({ inputProps, ...rest }) => {
  return (
    <Item {...rest}>{inputProps ? <Input {...inputProps} /> : <Input />}</Item>
  );
};

export default StyledInputItem;
