import React, { FC, useEffect, useState } from 'react';
import {
  getProductContactConstraints,
  patchProduct,
  Product,
  ProductContactConstraint,
} from '@apps/products/services';
import Tabs from '@components/Tabs';
import { Spin } from 'antd';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import PurchasesTabContext from '@apps/purchases/mainMenu/purchasesTabForProduct/context';
import CanBeBought from '@apps/purchases/mainMenu/purchasesTabForProduct/CanBeBought';
import { isAppActivatedInEcosystem } from '@apps/utils';
import PurchasesAppConfig from '@apps/purchases';
import { FormattedMessage } from 'react-intl';
import TaxCategory from '@apps/purchases/mainMenu/purchasesTabForProduct/TaxCategory';
import { getEcosystemTaxCategories } from '@apps/finance/services/taxCategories';
import { getPlatformTaxRates } from '@services/dictionary';
import removeUndefined from '@core/helpers/removeUndefined';
import { GridTwoColumns } from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/styles.sc';
import PurchasePlot from '@apps/purchases/mainMenu/purchasesTabForProduct/PurchasePlot';
import intl from '../../../../../intl';
import Suppliers from '@apps/purchases/mainMenu/purchasesTabForProduct/Suppliers';
import { useCustomEventListener } from '@core/services';
import { RefreshConstraintEvent } from '@apps/products/mainMenu/components/DetailedProduct/AddProductContactConstraint';

const { TabPane } = Tabs;

export const getEntireForm = (forms: any) => {
  return Object.values(forms).reduce((acc: any, formEl: any) => {
    acc = {
      ...acc,
      ...formEl.getFieldValue(),
    };
    return acc;
  }, {});
};

type TabProps = {
  integratedAppId: string;
  fullEcosystem: Ecosystem;
  forms: any;
  id?: string;
  readOnly?: boolean;
  initialValues?: Partial<Product>;
  onCreateSubmit?: (values: any) => void;
  onSubmitSuccess?: () => void;
};

type GetPurchasesTab = (payload: TabProps) => any;

const PurchasesTabForProduct: FC<TabProps> = ({
  integratedAppId,
  id,
  initialValues,
  fullEcosystem,
  readOnly = true,
  onSubmitSuccess,
  forms,
  onCreateSubmit,
}) => {
  const [taxRates, setTaxRates] = useState<any[]>([]);
  const [constraints, setConstraints] = useState<ProductContactConstraint[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && fullEcosystem) {
      setLoading(true);
      getProductContactConstraints({
        ecosystemId: fullEcosystem.id,
        productId: id,
        type: 'supplier',
      })
        ?.then(setConstraints)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fullEcosystem, id]);

  useCustomEventListener<'refreshConstraint', RefreshConstraintEvent>(
    'refreshConstraint',
    (data) => {
      if (data && data?.type === 'supplier') {
        if (id && fullEcosystem) {
          setLoading(true);
          getProductContactConstraints({
            ecosystemId: fullEcosystem.id,
            productId: id,
            type: 'supplier',
          })
            ?.then(setConstraints)
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    },
  );

  useEffect(() => {
    if (fullEcosystem) {
      setLoading(true);
      getEcosystemTaxCategories(fullEcosystem.id)
        ?.then((el) =>
          el.length > 0
            ? setTaxRates(el)
            : getPlatformTaxRates().then((elements) =>
                setTaxRates(
                  elements.map((tax) => ({
                    ...tax,
                    id: tax.value,
                  })),
                ),
              ),
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fullEcosystem]);

  return (
    <Spin spinning={loading}>
      <PurchasesTabContext.Provider
        value={{
          taxRates,
          productId: id,
          product: initialValues,
          ecosystem: fullEcosystem,
          readOnly,
        }}
      >
        <AppEntityForm
          elId={id}
          initialValues={{ ...initialValues }}
          appId={integratedAppId}
          name="purchasesTab"
          readOnly={readOnly}
          onSubmit={(values) => {
            if (id) {
              setLoading(true);
              patchProduct(id as string, {
                ...values,
                purchaseDetails: values.purchaseDetails
                  ? removeUndefined(values.purchaseDetails)
                  : {},
              })
                .then(() => {
                  setLoading(false);
                  if (onSubmitSuccess) {
                    onSubmitSuccess();
                  }
                })
                .catch((e) => {
                  setLoading(false);
                  console.error(e);
                });
            } else {
              const entireProduct = getEntireForm(forms);
              if (onCreateSubmit) {
                onCreateSubmit(entireProduct);
              }
            }
          }}
        >
          {() => {
            return (
              <div>
                <GridTwoColumns style={{ marginTop: '20px' }}>
                  <div>
                    <CanBeBought readOnly={readOnly} />
                    <TaxCategory
                      readOnly={readOnly}
                      ecosystemId={fullEcosystem.id}
                    />
                    <Suppliers constraints={constraints} />
                  </div>
                  <div>
                    <div>
                      {intl.formatMessage({
                        id: 'order.volume',
                        defaultMessage: 'Order volume',
                      })}
                    </div>
                    <PurchasePlot />
                  </div>
                </GridTwoColumns>
              </div>
            );
          }}
        </AppEntityForm>
      </PurchasesTabContext.Provider>
    </Spin>
  );
};

export const getPurchasesTab: GetPurchasesTab = (props) => {
  const { fullEcosystem } = props;
  const shouldRender = isAppActivatedInEcosystem(
    fullEcosystem,
    PurchasesAppConfig.todixId,
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <TabPane
      key={PurchasesAppConfig.todixId}
      tab={
        <>
          <PurchasesAppConfig.mainMenu.icon />
          <FormattedMessage id="app.purchases" defaultMessage="Purchases" />
        </>
      }
    >
      <PurchasesTabForProduct {...props} />
    </TabPane>
  );
};
