import { styled } from '@styles/themes';
import { Table, Form } from 'antd';
import MoneyInput from '@components/MoneyInput';

const { Item } = Form;

export const PositionsContainer = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

export const CustomTable = styled(Table)`
  max-width: 100%;
  overflow: auto;
  && {
    .ant-table {
      font-size: 12px !important;
    }
    td {
      text-align: center;
      padding: 8px 8px;
    }
    .ant-table-thead > tr > th {
      padding: 8px 8px;
    }
  }
`;

export const ExpandedRowContainer = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  grid-column-gap: 10px;
`;

export const ItemNoMargin = styled(Item)`
  margin: 0;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  //max-width: 80px;
`;
