import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import intl from '../../../../../intl';

const { Item } = Form;

const ExpectedDeliveryDate: FC = () => {
  return (
    <Item shouldUpdate noStyle>
      {({ getFieldValue, setFieldsValue }) => {
        const needDate: moment.Moment = getFieldValue('needDate');
        const standardDeliveryTime = getFieldValue('standardDeliveryTime');
        const oldExpectedDeliveryDate = getFieldValue('expectedDeliveryDate');
        if (needDate && standardDeliveryTime) {
          const expectedDeliveryDate = moment(needDate).add(
            Number(standardDeliveryTime),
            'days',
          );
          if (oldExpectedDeliveryDate) {
            if (
              moment.isMoment(oldExpectedDeliveryDate) &&
              !expectedDeliveryDate.isSame(oldExpectedDeliveryDate)
            ) {
              setFieldsValue({
                expectedDeliveryDate,
              });
            }
          } else {
            setFieldsValue({
              expectedDeliveryDate,
            });
          }
          return (
            <Item
              name="expectedDeliveryDate"
              label={intl.formatMessage({
                id: 'expected.delivery.date',
                defaultMessage: 'Expected delivery date',
              })}
            >
              <DatePicker disabled format={moment.defaultFormat} />
            </Item>
          );
        }
        return null;
      }}
    </Item>
  );
};

export default ExpectedDeliveryDate;
