import { BucketStep } from '@apps/purchases/services/buckets';

const stepTypeToDocType: Record<BucketStep, string> = {
  banf: 'requestForQuotation',
  cancelled: 'requestForQuotation',
  delivered: '',
  invoiced: 'invoice',
  paid: 'invoice',
  orderConfirmed: 'order',
  partlyPaid: 'invoice',
  partlyInvoiced: 'invoice',
  partlyDelivered: 'order',
  rfq: 'requestForQuotation',
  ordered: 'order',
};

export default stepTypeToDocType;
