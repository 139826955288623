import { RangeOfNumbersType } from '@services/rangeOfNumbers';
import { ApiOptions, put } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import { COLLECTION_NAME } from '@apps/purchases/services/rangeOfNumbers/namespace';
import moment from 'moment';

const addPurchasesRangeOfNumbers = (
  rangeOfNumbers: RangeOfNumbersType,
  id: string,
  options?: ApiOptions,
) =>
  put(
    `${COLLECTION_NAME}/${id}`,
    {
      ...removeUndefinedProperties(rangeOfNumbers),
      timestamp: moment().valueOf(),
    },
    options,
  );

export default addPurchasesRangeOfNumbers;
