import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { Spin, Form, Button, message, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import SelectedEcosystem from '@components/SelectedEcosystem';
import AppConfig, { SALES_ACTION } from '@apps/sales';
import {
  ButtonContainer,
  PageViewDrawer,
  RightColumn,
  ViewLayout,
} from '@apps/sales/mainMenu/components/SaleCreator/styles.sc';
import PagePreview from '@apps/sales/mainMenu/components/SaleCreator/pagePreview';
import SaleCreatorSettings from '@apps/sales/mainMenu/components/SaleCreator/SaleCreatorSettings';
import { useIntl } from 'react-intl';
import { supportedFileTypes } from '@apps/sales/settings/RangeOfNumbers/consts';
import OrderType from '@apps/sales/mainMenu/components/SaleCreator/components/OrderType';
import { getEcosystemTaxCategories } from '@apps/finance/services/taxCategories';
import { useSelector } from 'react-redux';
import {
  checkUserPermissions,
  getActiveEcosystemsForApp,
} from '@components/EcosystemIndicator/store';
import getLayout from '@apps/sales/settings/layouts';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import createMainTemplate from '@apps/sales/mainMenu/components/pdfTemplates/createMainTemplate';
import StepType from '@apps/sales/mainMenu/components/SaleCreator/components/StepType';
import { blobToFile } from '@apps/utils';
import { firebase } from '@services/Firebase';
import storage from '@services/Firebase/Storage';
import { Document, postDocument } from '@apps/documents/service';
import moment from 'moment/moment';
import {
  addSalesRangeOfNumbers,
  getRangeOfNumbersByEcosystem,
} from '@services/rangeOfNumbers';
import {
  addSaleOperation,
  getSalesOperationsForSale,
  SaleOperation,
  updateSaleOperation,
} from '@apps/sales/services/saleOperation';
import createSale from '@apps/sales/services/createSale';
import { useHistory } from 'react-router-dom';
import getSale from '@apps/sales/services/getSale';
import { useCustomEventListener } from '@core/services';
import { Sale } from '@apps/sales/services';
import updateSale from '@apps/sales/services/updateSale';
import createRelation from '@services/entityRelations/createRelation';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getPlatformTaxRates } from '@services/dictionary';
import { rootStore, RootStore } from '@core/store';

const { Item } = Form;

type Props = {
  saleId?: string;
  sale?: Sale | null;
  ecosystemId?: string;
  action: SALES_ACTION;
  relatedDocuments?: Document[];
};

const allowingTypes = Object.keys(supportedFileTypes);

const SaleCreator: FC<Props> = ({
  saleId,
  ecosystemId: passedEcosystemId,
  action,
  relatedDocuments,
}) => {
  const asDraft = useRef(false);
  const history = useHistory();
  const intl = useIntl();
  const [form] = useForm();
  const activeEcosystems = useSelector((state) =>
    getActiveEcosystemsForApp(state as RootStore, AppConfig.todixId, action),
  );
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [rangeOfNumbers, setRangeOfNumbers] = useState<any>(null);
  const [relatedSaleSteps, setRelatedSaleSteps] = useState<SaleOperation[]>([]);
  const [prevSaleState, setPrevSaleState] = useState<Sale | null>(null);

  const canProceed = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    'update-sales' as SALES_ACTION,
    passedEcosystemId || '',
  );

  useCustomEventListener('setSaleStep', (step: any) => {
    if (step && step?.type) {
      const relatedStep = relatedSaleSteps.find((s) => s.step === step.type);
      if (relatedStep) {
        const values = {
          type: relatedStep.step,
          status: relatedStep.status,
          stepId: relatedStep.id,
        };
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ...values,
        });
      } else {
        const values = {
          type: step.type,
          status: 'empty',
          stepId: undefined,
        };
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ...values,
        });
      }
    }
  });

  const setTaxCategoriesForEcosystem = useCallback(
    (ecosystemId) => {
      if (ecosystemId) {
        getEcosystemTaxCategories(ecosystemId)?.then(async (taxCategories) => {
          if (taxCategories.length > 0) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              taxCategories,
            });
          } else {
            const platformTaxRates = await getPlatformTaxRates();
            form.setFieldsValue({
              ...form.getFieldsValue(),
              taxCategories: platformTaxRates.map(
                (tax) =>
                  ({
                    id: tax.value,
                    amount: tax.value,
                    label: tax[intl.locale],
                  } as unknown as TaxCategory),
              ),
            });
          }
        });
      }
    },
    [form, intl.locale],
  );

  useEffect(() => {
    if (saleId) {
      setLoading(true);
      getSale(saleId)
        .then((sale) => {
          if (sale) {
            setPrevSaleState(sale);
            setTaxCategoriesForEcosystem(sale.ecosystem);
          }
          getSalesOperationsForSale(saleId, sale.ecosystem, action)
            .then((salesOperations) => {
              let latestSalesOperation: Partial<SaleOperation> = {
                step: 'quotation',
                status: 'empty',
              };
              if (salesOperations.length > 0) {
                latestSalesOperation = salesOperations.sort(
                  (a, b) => (b.timestamp as number) - (a.timestamp as number),
                )[0];
                setRelatedSaleSteps(salesOperations);
              }
              const values = {
                ...sale,
                ecosystem: sale.ecosystem,
                products: sale.products,
                relatedContact: sale.contact,
                relatedContactSnapshot: sale.contactSnapshot,
                type: latestSalesOperation.step,
                status: latestSalesOperation.status,
                stepId: latestSalesOperation.id,
                saleId,
              };
              form.setFieldsValue({
                ...form.getFieldsValue(),
                ...values,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [action, form, saleId, setTaxCategoriesForEcosystem]);

  const setNumbers = useCallback(
    (ecosystem) => {
      if (ecosystem) {
        setLoading(true);
        getRangeOfNumbersByEcosystem(ecosystem)
          ?.then((el) => {
            if (el !== undefined) {
              setRangeOfNumbers(el);
            } else {
              const initialRange = {
                ecosystem,
                fileTypes: {
                  ...Object.keys(supportedFileTypes).reduce(
                    (prev: any, fileType) => {
                      prev[fileType] = {
                        abbreviation: intl.formatMessage({
                          id: `settings.sales.rangeOfNumbers.supportedFileTypes.abbreviation.${fileType}`,
                          defaultMessage: `settings.sales.rangeOfNumbers.supportedFileTypes.abbreviation.${fileType}`,
                        }),
                        minLength: 2,
                      };
                      return prev;
                    },
                    {},
                  ),
                },
              };
              setRangeOfNumbers(initialRange);
            }
          })
          ?.finally(() => {
            setLoading(false);
          });
      }
    },
    [intl],
  );

  useEffect(() => {
    if (passedEcosystemId) {
      form.setFieldsValue({
        ecosystem: passedEcosystemId,
      });
      setNumbers(passedEcosystemId);
    }
  }, [form, passedEcosystemId, setNumbers]);

  const handleCancel = useCallback(() => {
    form.resetFields();
  }, [form]);
  const handleSubmit = useCallback(
    async (values: SalesFormValues) => {
      if (!rangeOfNumbers) {
        message.error(
          intl.formatMessage({
            id: 'error.request',
            defaultMessage: 'Error while processing request',
          }),
        );
        return;
      }
      if (!canProceed) {
        return;
      }

      const { ecosystem, type, products, stepId } = values;
      const saveAsDraft = asDraft.current;

      if (products.length < 1) {
        message.error(
          intl.formatMessage({
            id: 'sales.products.error',
            defaultMessage: 'Please add at least one product',
          }),
        );
        return;
      }

      try {
        const utcDate = moment().utc();
        setLoading(true);
        const user = firebase.auth.currentUser;
        let refSaleId = saleId;
        let documentId = '';
        const status: SaleOperation['status'] = saveAsDraft
          ? 'pending'
          : 'success';

        if (!saveAsDraft) {
          //All processing after approve changes
          const eco = activeEcosystems.find((eco) => eco.id === ecosystem);
          let layout = [];
          if (type) {
            layout = await getLayout(type);
          }

          const avatar: string = eco && eco.avatarUrl ? eco.avatarUrl : '';

          const doc = await createMainTemplate({
            values,
            intl,
            layout,
            ecosystem: eco,
            avatar,
          });

          const fileName = `${ecosystem}_${values.docNumber as string}`;
          const blob = doc.output('blob');

          const fileToUpload = {
            file: blobToFile(blob, fileName),
            ecosystem: values.ecosystem,
            fileDetails: {
              format: 'pdf',
              size: blob.size,
            },
          };

          const snapshot = await storage.upload(
            `documents/${fileName}`,
            fileToUpload.file as unknown as Blob,
          );
          const documentUrl = await snapshot?.ref.getDownloadURL();

          documentId = await postDocument(
            {
              documentUrl,
              docNumber: `${values.docNumber as string}`,
              referenceNumber: `${(values.referenceNumber as string) || ''}`,
              documentDueDate: values.validTill,
              ecosystem: fileToUpload.ecosystem,
              fileDetails: fileToUpload.fileDetails as Document['fileDetails'],
              type,
              uploadingUser: user?.uid,
              uploadingUserId: user?.uid,
              creatingUser: user?.uid,
              uploadDate: utcDate,
              receivingDate: utcDate,
              issuingDate: utcDate,
              creationDate: utcDate,
              relatedContact: values.relatedContactSnapshot?.id || '',
              relatedContactSnapshot: values.relatedContactSnapshot || {},
              isDraft: false,
              category: 'outgoing',
            },
            {
              message: false,
            },
          );
        }

        await addSalesRangeOfNumbers(
          {
            ...rangeOfNumbers,
            fileTypes: {
              ...rangeOfNumbers?.fileTypes,
              [type as string]: {
                ...rangeOfNumbers?.fileTypes?.[type as string],
                nextNumber: rangeOfNumbers?.fileTypes?.[type as string]
                  ?.nextNumber
                  ? Number(
                      rangeOfNumbers?.fileTypes?.[type as string]?.nextNumber,
                    ) + 1
                  : 1,
              },
            },
          },
          ecosystem as string,
          {
            message: false,
          },
        );

        if (!saleId) {
          refSaleId = await createSale({
            contact: values.relatedContactSnapshot?.id as string,
            contactSnapshot: values.relatedContactSnapshot,
            ecosystem: values.ecosystem as string,
            products: values.products,
            deliveryTerms: values.deliveryTerms || null,
            paymentTerms: values.paymentTerms || null,
            paymentStatus: 'running',
            sellerId: user?.uid as string,
            orderId: values.orderId || '',
          });
          await createRelation({
            timestamp: utcDate.unix(),
            ecosystem: values.ecosystem as string,
            parentId: refSaleId,
            parentType: 'sale',
            parentRefCollection: 'sales',
            childId: documentId,
            childType: 'document',
            childRefCollection: 'documents',
          });
        }
        if (refSaleId) {
          if (prevSaleState && !saveAsDraft) {
            if (
              JSON.stringify(prevSaleState.products) !==
                JSON.stringify(values.products) ||
              prevSaleState.contact !== values.relatedContactSnapshot?.id ||
              prevSaleState.orderId !== values.orderId
            ) {
              await updateSale(refSaleId, {
                contact: values.relatedContactSnapshot?.id as string,
                contactSnapshot: values.relatedContactSnapshot,
                ecosystem: values.ecosystem as string,
                products: values.products,
                deliveryTerms:
                  values.deliveryTerms || prevSaleState.deliveryTerms || null,
                paymentTerms:
                  values.paymentTerms || prevSaleState.paymentTerms || null,
                orderId: values.orderId || prevSaleState.orderId || '',
              });
            }
          }

          const saleOperation: SaleOperation = {
            saleId: refSaleId,
            creatorSnapshot: {
              displayName: user?.displayName as string,
              photoURL: user?.photoURL as string,
              email: user?.email as string,
            },
            creationDate: utcDate,
            contact: values.relatedContactSnapshot?.id as string,
            contactSnapshot: values.relatedContactSnapshot,
            ecosystem: values.ecosystem as string,
            products: values.products,
            deliveryDate: utcDate,
            step: type as string,
            orderId: values.orderId || '',
            status,
            documentId,
          };

          if (!stepId) {
            await addSaleOperation(saleOperation);
          } else {
            await updateSaleOperation(stepId, saleOperation);
          }

          setLoading(false);
          asDraft.current = false;
          if (!saveAsDraft) {
            history.push(`/app/${AppConfig.todixId}`);
          }
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    [
      canProceed,
      activeEcosystems,
      history,
      intl,
      prevSaleState,
      rangeOfNumbers,
      saleId,
    ],
  );

  return (
    <Spin spinning={loading}>
      <div>
        <AppEntityForm
          elId={saleId}
          appId={AppConfig.todixId}
          onSubmit={handleSubmit}
          autoComplete="off"
          initialValues={
            passedEcosystemId
              ? {
                  ecosystem: passedEcosystemId,
                  products: [],
                  relatedContact: undefined,
                  relatedContactSnapshot: undefined,
                  deliveryTerms: undefined,
                  creationDate: moment().utc(),
                }
              : { creationDate: moment().utc() }
          }
          name="salesCreator"
          providedForm={form}
          readOnly={!canProceed}
          disabledEditButton={!canProceed}
          disabledSubmitButton={!canProceed}
          style={{ height: `calc(100vh - 240px)` }}
          hideButtons={true}
        >
          {() => {
            return (
              <div>
                <ViewLayout>
                  <Item noStyle shouldUpdate={true}>
                    {({ getFieldsValue }) => (
                      <PagePreview
                        values={getFieldsValue()}
                        readOnly={!canProceed}
                        relatedDocuments={relatedDocuments}
                      />
                    )}
                  </Item>
                </ViewLayout>
                <PageViewDrawer
                  closable
                  visible={collapsed}
                  destroyOnClose={false}
                  onClose={() => setCollapsed(false)}
                  placement="right"
                  getContainer={false}
                  mask={false}
                  closeIcon={
                    <Button
                      icon={<ArrowRightOutlined />}
                      type="primary"
                    ></Button>
                  }
                >
                  <RightColumn>
                    <SelectedEcosystem
                      disabled={!!saleId || !canProceed}
                      appId={AppConfig.todixId}
                      onChange={(ecosystemId: any) => {
                        setTaxCategoriesForEcosystem(ecosystemId);
                        setNumbers(ecosystemId);
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          products: [],
                          relatedContact: undefined,
                          relatedContactSnapshot: undefined,
                          deliveryTerms: undefined,
                        });
                      }}
                      action={'view-sales' as SALES_ACTION}
                    />
                    <StepType
                      allowingTypes={allowingTypes}
                      relatedSaleSteps={relatedSaleSteps}
                    />
                    <OrderType />
                    <ButtonContainer>
                      <Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const status = getFieldValue('status');
                          const disabled =
                            loading || !['empty', 'pending'].includes(status);
                          const draftShown = status !== 'success';
                          return (
                            <>
                              <Button
                                type="default"
                                htmlType="button"
                                disabled={loading || !canProceed}
                                onClick={handleCancel}
                              >
                                {intl.formatMessage({
                                  id: 'button.cancel',
                                  defaultMessage: 'Cancel',
                                })}
                              </Button>
                              <Space direction="horizontal">
                                {draftShown && (
                                  <Button
                                    type="dashed"
                                    htmlType="submit"
                                    disabled={loading || !canProceed}
                                    onClick={() => {
                                      asDraft.current = true;
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'button.save.draft',
                                      defaultMessage: 'Save as draft',
                                    })}
                                  </Button>
                                )}
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  disabled={disabled || !canProceed}
                                >
                                  {intl.formatMessage({
                                    id: 'button.save',
                                    defaultMessage: 'Save',
                                  })}
                                </Button>
                              </Space>
                            </>
                          );
                        }}
                      </Item>
                    </ButtonContainer>
                  </RightColumn>
                </PageViewDrawer>
                {!collapsed && (
                  <SaleCreatorSettings
                    onCollapseClick={() => setCollapsed(true)}
                  />
                )}
                <Form.Item name="docNumber" hidden />
                <Form.Item name="relatedContactSnapshot" hidden />
                <Form.Item name="products" hidden />
                <Form.Item name="taxCategories" hidden />
                <Form.Item name="status" hidden />
                <Form.Item name="stepId" hidden />
                <Form.Item name="saleId" hidden />
              </div>
            );
          }}
        </AppEntityForm>
      </div>
    </Spin>
  );
};

export default SaleCreator;
