import { Form, Input, Tooltip } from 'antd';
import React, { FC, useState, useRef, useContext, useEffect } from 'react';
import EditableContext from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/context';
import UnitSelector from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/UnitSelector';
import PriceOutput from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/PriceOutput';
import Amount from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/Amount';
import PriceInOrder from './PriceInOrder';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  ModifiedIndicator,
  CustomCell,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import intl from '../../../../../../../intl';
import { BucketPosition } from '@apps/purchases/services/buckets';

const { Item } = Form;

export type EditableBucketPosition = BucketPosition & {
  isDirty?: boolean;
  key: number;
  totalUnitAmount: any;
};

type EditableCellProps = {
  title: React.ReactNode;
  editable: boolean;
  type?: 'dropdown' | 'number' | 'string' | 'unit' | 'price';
  children: React.ReactNode;
  dataIndex: keyof EditableBucketPosition;
  record: EditableBucketPosition;
  handleSave: (record: EditableBucketPosition) => void;
  prodOptions: any[];
  selectedEcosystem: string | null;
  readOnly: boolean;
};

const EditableCell: FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  selectedEcosystem,
  prodOptions,
  readOnly,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current!.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
      });
    }
  }, [form, record]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    if (readOnly) {
      return;
    }
    try {
      const values: Record<string, string | number> =
        await form.validateFields();
      const newValues = { ...record, ...values };
      handleSave(newValues);
      toggleEdit();
    } catch (errInfo) {
      const errorMessage = `Update failed: ${errInfo}`;
      console.error(errorMessage);
    }
  };

  let childNode = children;

  if (dataIndex === 'productId') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '160px' }}>
        {record.isDirty && (
          <Tooltip
            title={intl.formatMessage({
              id: 'position.modified',
              defaultMessage: 'This position has been modified',
            })}
          >
            <ModifiedIndicator
              icon={<ExclamationCircleOutlined />}
              color="warning"
            />
          </Tooltip>
        )}
      </CustomCell>
    );
  }

  if (dataIndex === 'needAmount') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '64px' }}>
        <Amount
          name="needAmount"
          onBlur={save}
          onPressEnter={save}
          handleSave={save}
          disabled={readOnly}
        />
      </CustomCell>
    );
  }

  if (dataIndex === 'totalUnitAmount') {
    return (
      <CustomCell {...restProps}>
        <PriceOutput name="totalUnitAmount" />
      </CustomCell>
    );
  }

  if (dataIndex === 'unitAmount') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '100px' }}>
        <PriceInOrder
          fieldName="unitAmount"
          product={record}
          //onBlur={save}
          handleSave={save}
          disabled={readOnly}
        />
      </CustomCell>
    );
  }

  if (type === 'unit') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '120px' }}>
        <Item style={{ margin: 0 }} name={['unit']}>
          <UnitSelector
            defaultValue={record.unit}
            selectedEcosystem={selectedEcosystem}
            onBlur={save}
            style={{
              width: '100%',
            }}
            disabled={readOnly}
          />
        </Item>
      </CustomCell>
    );
  }

  if (editable) {
    childNode = editing ? (
      <Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          type={type === 'number' ? 'number' : 'text'}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          disabled={readOnly}
        />
      </Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
        <Item name={dataIndex} hidden={true} />
      </div>
    );
  } else if (dataIndex) {
    childNode = (
      <Item noStyle shouldUpdate={true}>
        {() => (
          <Item name={dataIndex} style={{ margin: 0 }}>
            <Input readOnly />
          </Item>
        )}
      </Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
