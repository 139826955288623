import React, { FC, useEffect, useState, useCallback } from 'react';
import { Spin, Button } from 'antd';
import { useIntl } from 'react-intl';
import {
  addPurchaseOperation,
  getPurchaseOperationSettings,
  updatePurchaseOperationSettings,
} from '@apps/purchases/services/purchaseOperation/purchaseOperationSettings';
import { PurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation/namespace';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig from '@apps/purchases';
import { useForm } from 'antd/lib/form/Form';
import {
  ButtonsContainer,
  Flex,
  StyledSectionTitle,
} from '@apps/purchases/settings/operationSteps/style.sc';
import FooterContent from '@apps/purchases/settings/operationSteps/FooterContent';
import FreeTextField from '@apps/purchases/settings/operationSteps/FreeTextField';

type StepSettingsProps = {
  step: string;
  ecosystemId: string;
  ecosystem: Ecosystem;
};

const StepSettings: FC<StepSettingsProps> = ({ step, ecosystemId }) => {
  const intl = useIntl();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    footer: {
      content: ['', '', '', ''],
    },
  } as Omit<PurchaseOperationSettings, 'ecosystem' | 'step'>);
  useEffect(() => {
    setIsLoading(true);
    getPurchaseOperationSettings(step, ecosystemId)
      .then((results) => {
        if (results.length) {
          setSettings(results[0]);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ...results[0],
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ecosystemId, form, step]);
  const handleSubmit = useCallback(
    async (values: PurchaseOperationSettings) => {
      setIsLoading(true);
      values.footer.content = Object.values(values.footer.content);
      if (settings.id) {
        await updatePurchaseOperationSettings(settings.id, values);
      } else {
        await addPurchaseOperation({
          ...values,
          step,
          ecosystem: ecosystemId,
        });
      }
      setIsLoading(false);
    },
    [settings, ecosystemId, step],
  );
  return (
    <Spin spinning={isLoading}>
      <div>
        <AppEntityForm
          appId={AppConfig.todixId}
          initialValues={settings}
          onSubmit={handleSubmit}
          style={{ height: `calc(100vh - 240px)` }}
          providedForm={form}
          readOnly={false}
          hideButtons={true}
        >
          {() => {
            return (
              <Flex>
                <FreeTextField
                  fieldName="freeTextStart"
                  fieldLabel={intl.formatMessage({
                    id: 'free.text.start',
                    defaultMessage: 'Free text start',
                  })}
                  size="large"
                  rows={2}
                  showCount
                  maxLength={115}
                />
                <FreeTextField
                  fieldName="freeTextEnd"
                  fieldLabel={intl.formatMessage({
                    id: 'free.text.end',
                    defaultMessage: 'Free text end',
                  })}
                  size="large"
                  rows={2}
                  showCount
                  maxLength={115}
                />
                <StyledSectionTitle level={5}>
                  {intl.formatMessage({
                    id: 'sales.operations.steps.settings.footer',
                    defaultMessage: 'Footer',
                  })}
                </StyledSectionTitle>
                <FooterContent />
                <ButtonsContainer>
                  <Button type="primary" htmlType="submit">
                    {intl.formatMessage({
                      id: 'button.save',
                      defaultMessage: 'Save',
                    })}
                  </Button>
                </ButtonsContainer>
              </Flex>
            );
          }}
        </AppEntityForm>
      </div>
    </Spin>
  );
};

export default StepSettings;
