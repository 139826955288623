import { PageLayout } from '@apps/purchases/services';
import commonSections from './common.layouts.sections';

const layout: PageLayout = [
  ...commonSections,
  {
    field: 'supplier',
    style: {
      gridArea: 'supplier',
      display: 'flex',
      fontSize: '12px',
      position: 'relative',
      marginTop: '20px',
      marginBottom: '20px',
      minWidth: '280px',
      maxWidth: '280px',
    },
  },
  {
    field: 'freeTextStart',
    style: {
      gridArea: 'freeTextStart',
      gridColumnEnd: 'span 3',
      display: 'flex',
      fontSize: '12px',
    },
  },
  {
    field: 'freeTextEnd',
    style: {
      gridArea: 'freeTextEnd',
      gridColumnEnd: 'span 3',
      display: 'flex',
      fontSize: '12px',
    },
  },
  {
    field: 'deliveryAddress',
    style: {
      gridArea: 'deliveryAddress',
      gridColumnEnd: 'span 3',
      display: 'flex',
      fontSize: '12px',
    },
  },
];

export default layout;
