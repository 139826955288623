import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AppView from '@components/AppView';
import optionsMapper from '@core/helpers/optionsMapper';
import appConfig from '@apps/purchases';
import columns from '@apps/purchases/mainMenu/operationSteps/columns';
import getRows from '@apps/purchases/mainMenu/operationSteps/getRows';

const OperationSteps: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'operation.steps',
            defaultMessage: 'Operation steps',
          }),
        ],
      ]}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={() => <div />}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      options={optionsMapper(['open'], intl)}
      appConfig={appConfig}
    />
  );
};

export default OperationSteps;
