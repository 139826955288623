import React, { FC, useState, useEffect } from 'react';
import intl from '../../../../../intl';
import { Form, Tooltip } from 'antd';
import { ModifiedIndicator } from '@apps/purchases/mainMenu/components/ProductsWithPrices/styles.sc';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DescriptionContainer } from '@apps/purchases/mainMenu/components/styles.sc';
import Description from '@apps/purchases/mainMenu/components/individualRequest/Description';
const { Item } = Form;

type DescriptionWithIndicatorProps = {
  description: string;
};

const DescriptionWithIndicator: FC<DescriptionWithIndicatorProps> = ({
  description,
}) => {
  const [desc, setDesc] = useState<string>(description);
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    if (description === desc) {
      setShowIndicator(false);
    }
  }, [desc, description]);

  useEffect(() => {
    if (description !== desc) {
      setDesc(description);
    }
  }, [desc, description]);

  return (
    <DescriptionContainer>
      <Description readOnly={false} />
      <Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const newValue = getFieldValue('description');

          setShowIndicator(newValue !== desc && desc !== '');

          return (
            showIndicator && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'description.modified',
                  defaultMessage: 'Description has been modified',
                })}
              >
                <ModifiedIndicator
                  icon={<ExclamationCircleOutlined />}
                  color="warning"
                />
              </Tooltip>
            )
          );
        }}
      </Item>
    </DescriptionContainer>
  );
};

export default DescriptionWithIndicator;
