import { jsPDF } from 'jspdf';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';
import moment from 'moment';

const addDocumentData = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
) => {
  const { intl, values } = props;
  const { referenceNumber, validTill, creationDate } = values;
  let newLastPositionY = lastYPosition;
  const posX = 140;
  if (referenceNumber) {
    const reference = `${intl.formatMessage({
      id: `reference`,
      defaultMessage: 'Reference',
    })}: ${referenceNumber}`;
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(reference, posX, newLastPositionY);
    newLastPositionY = newLastPositionY + 5;
  }
  if (validTill) {
    const validUntil = `${intl.formatMessage({
      id: `valid.until`,
      defaultMessage: 'Valid until',
    })}: ${moment(validTill).format('DD.MM.YYYY')}`;
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(validUntil, posX, newLastPositionY);
    newLastPositionY = newLastPositionY + 5;
  }
  if (creationDate) {
    const dateOfCreation = `${intl.formatMessage({
      id: `date`,
      defaultMessage: 'Date',
    })}: ${moment(creationDate).format('DD.MM.YYYY')}`;
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(dateOfCreation, posX, newLastPositionY);
    newLastPositionY = newLastPositionY + 5;
  }
  return newLastPositionY;
};

export default addDocumentData;
