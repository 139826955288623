import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';
import { Product } from '@apps/products/services';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import ProductImagesInCard from '@apps/products/mainMenu/components/ProductCard/ProductImagesInCard';
import {
  ActionContainer,
  CardCover,
  EcosystemContainer,
  ProductName,
  ProductNumber,
  StyledProductCard,
} from '@apps/products/mainMenu/components/styles';
import {
  ActionRenderer,
  ActionRendererProps,
} from '@components/AppView/ActionRenderer';
import { EllipsisOutlined } from '@ant-design/icons';

type Props = {
  item: Product;
  onCardClick(): void;
  actionRendererProps: ActionRendererProps;
};

const ProductCard: FC<Props> = ({
  item,
  onCardClick,
  actionRendererProps,
  children,
}) => {
  const fullEcosystem = useSelector(
    getEcosystemById(item.ecosystem),
  ) as Ecosystem;

  return (
    <StyledProductCard
      cover={
        <CardCover>
          <ProductImagesInCard
            productId={item.id as string}
            ecosystemId={item.ecosystem}
          />
          <ActionContainer>
            <ActionRenderer
              key="ellipsis"
              {...actionRendererProps}
              icon={<EllipsisOutlined />}
            />
          </ActionContainer>
          <EcosystemContainer>
            {fullEcosystem ? (
              <Avatar
                src={fullEcosystem.avatarUrl}
                shape="circle"
                size="default"
              />
            ) : null}
          </EcosystemContainer>
        </CardCover>
      }
      onClick={onCardClick}
    >
      <div>
        <ProductName>{item.productName}</ProductName>
        <ProductNumber>{item.productNumber}</ProductNumber>
      </div>
      {children}
    </StyledProductCard>
  );
};

export default ProductCard;
