import React, { FC, lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const PurchasesList = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './purchasesList'),
);

const Create = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './routes/Create'),
);

const Edit = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './routes/Edit'),
);

const PurchaseRequest = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './PurchaseRequest'),
);

const CreateIndividualRequest = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ './routes/CreateIndividualRequest'
    ),
);

const BANFList = lazy(
  () =>
    import(/* webpackChunkName: "purchasesApp" */ './individualRequestsList'),
);

const BanfEntryPreview = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ './routes/ViewIndividualRequest'
    ),
);

const ReorderStockParts = lazy(
  () =>
    import(/* webpackChunkName: "purchasesApp" */ './routes/ReorderStockParts'),
);

const OperationSteps = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './operationSteps'),
);

const OperationStep = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ './operationSteps/operationStep'
    ),
);

const Sales: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}/banf_list/:id`}
        component={BanfEntryPreview}
      />
      <Route
        exact
        path={`${path}/operation_steps/:id`}
        component={OperationStep}
      />
      <Route
        path={`${path}/reorder_stock_parts`}
        component={ReorderStockParts}
      />
      <Route path={`${path}/operation_steps`} component={OperationSteps} />
      <Route path={`${path}/banf_list`} component={BANFList} />
      <Route path={`${path}/create`} component={Create} />
      <Route path={`${path}/purchase_request`} component={PurchaseRequest} />
      <Route
        path={`${path}/create_individual_request`}
        component={CreateIndividualRequest}
      />
      <Route path={`${path}/purchases_list`} component={PurchasesList} />
      <Route exact path={`${path}/:id`} component={Edit} />
    </Switch>
  );
};

export default Sales;
