import React, { CSSProperties, FC, useState } from 'react';
import { IntlShape } from 'react-intl';
import { Contact } from '@apps/contacts/services';
import ContactSearch from '@apps/purchases/mainMenu/components/ContactSearch';
import useOutsideClick from '@core/hooks/useOutsideClick';
import {
  ContactLabel,
  Hoverable,
} from '@apps/purchases/mainMenu/components/styles.sc';

type Props = {
  style?: CSSProperties;
  intl: IntlShape;
  forPdf: boolean;
  contact?: Contact;
  disabled?: boolean;
  label?: string;
};

const ContactDetails: FC<Props> = ({
  style,
  contact,
  disabled = false,
  label,
}) => {
  const [editMode, setEditMode] = useState(false);
  const ref = useOutsideClick({
    callback: () => setEditMode(false),
  });

  const address = contact?.addresses
    ? contact.addresses.find((add) => add.standard)
    : undefined;

  if (!contact || editMode) {
    return (
      <div ref={ref} style={style as CSSProperties}>
        {label && <div>{label}</div>}
        <ContactSearch
          onContactSet={() => setEditMode(false)}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <Hoverable style={style as CSSProperties}>
      <div>
        {label && <ContactLabel>{label}</ContactLabel>}
        <div onClick={() => !disabled && setEditMode(true)}>
          <div>
            {contact?.type === 'person'
              ? `${contact.firstName || ''} ${contact.lastName || ''}`
              : `${contact?.companyName || ''}`}
          </div>
          {address && (
            <div>
              <div>{`${address.street || ''}, ${address.number || ''}`}</div>
              <div>{`${address.zipcode || ''}, ${address.city || ''}`}</div>
              <div>{`${address.country || ''}`}</div>
            </div>
          )}
        </div>
      </div>
    </Hoverable>
  );
};

export default ContactDetails;
