import { styled } from '@styles/themes';
import { Form } from 'antd';

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: -40px;
  right: 24px;
  z-index: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledForm = styled(Form)`
  & {
    .ant-radio-button-wrapper-disabled {
      background: transparent;
    }
    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
      background: transparent;
      color: #0e7d40;
      border: 1px solid #0e7d40;
    }

    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked:before {
      background: transparent;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background: transparent;
      color: #000;
    }

    .ant-picker.ant-picker-disabled {
      background: transparent;
      color: #000;
    }

    input[disabled] {
      cursor: not-allowed;
    }

    .ant-form-item {
      margin-bottom: 12px !important;
    }
  }
`;
