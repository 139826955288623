import React, { FC, useState } from 'react';
import { Spin } from 'antd';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';
import AppConfig, { PURCHASES_ACTION } from '@apps/purchases';
import IndividualRequest from '@apps/purchases/mainMenu/components/individualRequest';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { createIndividualPurchaseRequest } from '@apps/purchases/services/individualPurchaseRequests';
import { useHistory } from 'react-router-dom';
import { createActivity } from '@core/services';

const CreateIndividualRequest: FC = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const action: PURCHASES_ACTION = 'create-purchases-requests';
  const firstActiveEcosystemId = useFirstActiveEcosystemId(
    AppConfig.todixId,
    action,
  );

  const handleSubmit = async (values: IndividualPurchaseRequest) => {
    try {
      setIsSubmitting(true);
      const newRequest: IndividualPurchaseRequest = {
        ...values,
        status: 'done',
        stepType: 'banf',
      };
      await createIndividualPurchaseRequest(newRequest);
      await createActivity({
        ecosystemId: values.ecosystem,
        userId: values.creator.uid,
        title: 'purchase.request.created.by',
        content: values.creator.displayName,
      });
      setIsSubmitting(false);
      history.push(`/app/${AppConfig.todixId}/banf_list`);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  return (
    <Spin spinning={isSubmitting}>
      <IndividualRequest
        readOnly={false}
        initialValues={{
          ecosystem: firstActiveEcosystemId,
        }}
        action={action}
        onSubmit={handleSubmit}
        cancelLink={`/app/${AppConfig.todixId}/purchase_request`}
      />
    </Spin>
  );
};

export default CreateIndividualRequest;
